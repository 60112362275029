import React, { Component, useState } from "react";
import { useTable, useExpanded, useRowSelect } from "react-table";
import { Button, Modal, Table } from "react-bootstrap";
import AdminReportModal from "./AdminReportModal";
import { Fetch } from "../../helpers/Fetch";
import Fuse from "fuse.js";

class AdminReportTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openAdminReportModal: false,
      openAdminEditModal: false,
      edit_modal_data: {},
      devices: [],
      report_bill_id: 0,
      filterText: "",
    };
  }

  componentWillUnmount() {
    //Remove the session storage when the page refreshes or the component unmounts
    sessionStorage.removeItem("admin_report_row_id");
  }

  handleAdminReportModalOpen = () => {
    //This function handles Admin Report Modal close
    this.setState({
      openAdminReportModal: true,
    });
  };
  handleAdminReportModalClose = () => {
    //This function handles Admin Report Modal close
    this.setState({
      openAdminReportModal: false,
    });
  };

  // handleDeviceSave=()=>{
  //
  // }

  get_report_devices = (e, rbid, row) => {
    //This is an api call to get report devices for the add devices modal
    sessionStorage.setItem("admin_report_row_id", row.id);
    Fetch("admin_reports.php", {
      action: "get_report_devices",
      report_bill_id: rbid,
    }).then((data) => {
      this.setState({
        devices: data.devices,
        report_bill_id: rbid,
      });
      // this.devices=data.devices
    });
  };

  handleRowCollapse = () => {
    sessionStorage.removeItem("admin_report_row_id");
    this.setState({});
  };

  handleFilterChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    this.setState({
      [name]: value,
    });
  };

  handleShowAddModal = (e) => {
    console.log(this.props.reports);
  };

  render() {
    //A conventional render for all our pages to ensure no unnecessary renders
    console.count("AdminReportTable Render");
    const { openAdminReportModal, devices, report_bill_id, filterText } =
      this.state;
    const { edited_user_id, reports } = this.props;

    const fuse = new Fuse(reports, {
      keys: ["report_name", "report_bill_id", "report_note"],
      threshold: 0,
      ignoreLocation: true,
      isCaseSensitive: false, //false is the default, but I want this comment as a reminder
    });

    const search_results = fuse.search(filterText);

    const report_data = filterText
      ? search_results.map((result) => result.item)
      : reports;

    /*
     * This is the child component of the table row
     * */
    const sub_row_component = () => (
      <div>
        <div className="row align-items-center">
          <button
            className="btn btn-primary btn-sm offset-4 col-auto"
            onClick={this.handleAdminReportModalOpen}
          >
            Add Device
          </button>
          <h4 className="col-auto">Devices Table</h4>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>DID</th>
              <th>D.Name</th>
              <th>C.Name</th>
              <th>D. Comm Data</th>
              <th>D.Notes</th>
              <th>Enabled</th>
              <th>D.Type Name</th>
            </tr>
          </thead>
          <tbody>
            {devices.map((val, index) => (
              <tr key={index}>
                <td>{val.device_id}</td>
                <td>{val.device_name}</td>
                <td>{val.collector_name}</td>
                <td>{val.device_comm_data1}</td>
                <td>{val.device_notes}</td>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={val.enabled}
                    disabled
                  />{" "}
                </td>
                <td>{val.device_type_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

    /*
     * These are the columns for the React table component
     * */
    const columns = [
      {
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {sessionStorage.getItem("admin_report_row_id") === row.id ||
            row.isExpanded ? (
              <i
                className="fas fa-minus mx-2"
                style={{ color: "red" }}
                onClick={(e) => this.handleRowCollapse()}
              />
            ) : (
              <i
                className="fas fa-plus-square mx-2"
                style={{ color: "green" }}
                onClick={(e) =>
                  this.get_report_devices(e, row.original.report_bill_id, row)
                }
              />
            )}
          </span>
        ),
        width: 50,
      },

      {
        Header: () => <div className="me-5">RBID</div>, //Text of the header
        accessor: "report_bill_id", //Property name in data
        key: "report_bill_id",
        width: 50,
      },
      {
        Header: () => <div className="me-5">Report Name</div>,
        accessor: "report_name",
        key: "report_name",
        class: "w-30",
      },
      {
        Header: () => <div className="me-5">Report Note</div>,
        accessor: "report_note",
        key: "report_note",
        class: "w-50 d-none d-md-table-cell",
      },
    ];

    const IndeterminateCheckbox = React.forwardRef(
      ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
          resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
          <>
            <input type="checkbox" ref={resolvedRef} {...rest} />
          </>
        );
      }
    );

    //Build the table -- Note that this function is generic
    //There is no custom code here that changes with the number of columns
    function TableRender({ columns, data, sub_row_component }) {
      const handleSelectedRows = () => {
        let selected_ids = {};
        data.forEach((val, index) => {
          if (val.sel_by_default === true) {
            selected_ids[index] = true;
          }
        });
        return selected_ids;
      };

      const handleShowAddModal = (e, val) => {
        console.log("selectedFlatRows", selectedFlatRows);
        setAddModal(true);
        setAddButton(false);
        setAddData(val);
      };

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        visibleColumns,
      } = useTable(
        {
          columns,
          data,
          autoResetExpanded: false,
          autoResetPageIndex: false,
          resetPageOnDataChange: false,
          initialState: {
            pageIndex: 0,
            pageSize: 50,
            selectedRowIds: handleSelectedRows(),
          },
        },
        useExpanded,
        useRowSelect,
        (hooks) => {
          hooks.visibleColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox

              //True but we would need to do the checkbox selection ourselves ie. the Functionality
              //This is the top th checkbox that checks all the data in the table
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              //For individual rows its okay but we want to copy multiple rows
              //This is the checkbox for individual rows
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
              width: 25,
            },
            ...columns,
          ]);
        }
      );

      const [showAddModal, setAddModal] = useState(false);
      const [enableAddButton, setAddButton] = useState(false);
      const [addData, setAddData] = useState([]);
      const handleCloseAddModal = () => setAddModal(false);



      const handleBatchAddReports = () => {
        //Create array of tab_id to send to server
        console.log(addData);
        let rbid_array = [];
        addData.forEach((r) => {
          console.log(r);
          rbid_array.push(r.report_bill_id);
        });

        if(addData.length >= 1){
          Fetch(`admin_reports.php`, {
            action: "assign_site_reports",
            edited_user_id: edited_user_id,
            report_group_id:  addData[0].report_group_id, //All assigned reports are in the same group
            report_bill_id_array: rbid_array, //Copy these tabs
          }).then((data) => {
            console.log(data);
            setAddModal(false);
          });
        } else {
          setAddModal(false);
        }
        }


      //Sort the table rows by report name
      rows.sort((a, b) => a.report_name - b.report_name);

      console.log(addData, handleSelectedRows());


      return (
        <>
          <Modal show={showAddModal} onHide={handleCloseAddModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>User Reports Assign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Are you sure you want to Add selected Reports</h4>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseAddModal}>
                Cancel
              </Button>
              <Button
                variant="danger"
                disabled={enableAddButton}
                onClick={handleBatchAddReports}
              >
                Assign Reports
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="container">
            <button
                className="btn btn-sm btn-primary mb-2"
                onClick={(e)=>handleShowAddModal(e,selectedFlatRows.map((d) => d.original))}
                // onClick={(e) =>
                //     handleShowAddModal(
                //     e,
                //     selectedFlatRows.map((d) => d.original)
                //   )
                // }
            >
              Assign Reports To User
            </button>
            <Table
              striped
              bordered
              hover
              responsive
              size="sm"
              // variant="dark"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr key={"header"} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        key={column.key}
                        {...column.getHeaderProps({
                          className: column.class,
                        })}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  //Loop though the rows
                  prepareRow(row);

                  return (
                    <React.Fragment key={i}>
                      <tr key={row.values.site_id} {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps({
                                className: cell.column.class,
                              })}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                      {sessionStorage.getItem("admin_report_row_id") ===
                        row.id || row.isExpanded ? (
                        <tr>
                          <td colSpan={visibleColumns.length}>
                            {sub_row_component({ row })}
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </>
      );
    } //end of TableRender function

    return (
      <div className="col-12 mt-2">
        <div className="text-center mb-2">
          {/*<label className="ms-5 me-1">*/}
          {/*  <b>Search Report Table</b>*/}
          {/*</label>*/}
          {/*<input*/}
          {/*  type="text"*/}
          {/*  name="filterText"*/}
          {/*  onChange={this.handleFilterChange}*/}
          {/*/>*/}
        </div>
        <TableRender
          columns={columns}
          data={report_data}
          sub_row_component={sub_row_component}
          edited_user_id={edited_user_id}
        />
        {openAdminReportModal && (
          <AdminReportModal
            show_modal={openAdminReportModal}
            handle_modal_close={this.handleAdminReportModalClose}
            site_id={this.props.site_id}
            reload_report_modal={this.get_report_devices}
            report_bill_id={report_bill_id}
          />
        )}
      </div>
    );
  }
}

export default AdminReportTable;
