import React, {Component} from "react";
import Navigation from "../Navbar";
import AppFooter from "../AppFooter";
import "./Devices.css";

import SiteSelect from "../Global/Site_select2";

import CollectorTable3 from "./CollectorTable3";

class Devices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            site_id: sessionStorage.getItem("prev_site_id"),
            site_tz: sessionStorage.getItem("prev_site_tz"),
            expanded: [],
        };
    }


    handleSiteChange = (e) => {
        console.log("handleSiteChange state=", e.value);
        //Update storage in case of refresh or change to another tab
        sessionStorage.setItem("prev_site_id", e.value);
        sessionStorage.setItem("prev_site_tz", e.site_tz);
        sessionStorage.setItem("prev_site_name", e.label);

        this.setState({
            site_tz: e.site_tz,
            site_id: e.value,
        });
    };


    render() {

        const {site_id, site_tz} = this.state
        console.log("Devices Render", site_id);
        return (
            <div>
                <Navigation/>
                <div className="container-fluid">
                    <div className="col-sm-8 col-lg-4 col-xl-3 mx-auto">
                        <SiteSelect handleSiteChange={this.handleSiteChange}/>
                    </div>
                    <div className="mx-auto col-xxl-10 col-xl-12">
                        <CollectorTable3
                            site_id={site_id}
                            site_tz={site_tz}
                        />
                    </div>
                </div>
                <AppFooter/>
            </div>
        );
    }
}

export default Devices;
