import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import dayjs from "dayjs";
import "./Reading.css";
import ReadingsMobileModal from "./ReadingsMobileModal";
import { NumberFormat } from "../../helpers/NumberFormat";
import {Alert, Tooltip} from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { globalDateFormat } from "../../helpers/GlobalDateFormat";
import { isMobile } from "react-device-detect";
import Compressor from "compressorjs";
import ReadingsDesktopModal from "./ReadingsDesktopModal";
function ReadingsTable(props) {
  const [site_tz, set_site_tz] = useState("America/New_York")
  useEffect(()=>{
    set_site_tz(sessionStorage.getItem("prev_site_tz"))
  },[])




  const columns = useMemo(
    () => [

          {
            Header: "Device ID",
            accessor: "device_id", //Name in data
            class: isMobile ? "d-none" : "text-nowrap",
          },
          {
            accessor: "sort_order", //Name in data
            class: "text-nowrap ",
          },
          {
            accessor: "need_ocr", //Name in data
            class: "text-nowrap ",
          },
          {
            Header: "Device Name",
            accessor: "device_name",
            class: "text-nowrap",
          },
          {
            Header: "Device Type",
            accessor: "device_type_name",
            class: "text-nowrap d-none d-lg-table-cell",
          },
          {
            Header: "Tenant Name",
            accessor: "org_name",
          },
          {
            Header: "Last Read",
            accessor: "lr_reading",
            class: "text-nowrap d-none d-lg-table-cell",
            Cell: (props) => (
              <div>{NumberFormat().format(props.value) + " kWh"}</div>
            ),
          },
          {
            Header: "Last Read Time",
            accessor: "lr_gmt_timestamp",
            class: "text-nowrap d-none d-lg-table-cell",
            Cell: (props) => (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    <div>
                      {globalDateFormat(
                        props.value,
                        "YYYY-MM-DD  h:mm:ss A",
                        site_tz
                      )}
                    </div>
                  </Tooltip>
                }
                color="black"
              >
                <div>{dayjs(props.value).format("YYYY-MM-DD")}</div>
              </OverlayTrigger>
            ),
          },
          {
            Header: "Last Read 1",
            accessor: "lr1_reading",
            class: "text-nowrap d-none d-lg-table-cell",
            Cell: (props) => (
              <div>{NumberFormat().format(props.value) + " kWh"}</div>
            ),
          },
          {
            Header: "Last Read Time 1",
            accessor: "lr1_gmt_timestamp",
            class: "text-nowrap d-none d-lg-table-cell",
            Cell: (props) => (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    <div>
                      {globalDateFormat(
                        props.value,
                        "YYYY-MM-DD  h:mm:ss A ",
                        site_tz
                      )}
                    </div>
                  </Tooltip>
                }
                color="black"
              >
                <div>
                  {props.value ? dayjs(props.value).format("YYYY-MM-DD") : ""}
                </div>
              </OverlayTrigger>
            ),
          },
          {
            Header: "Last Read 2",
            accessor: "lr2_reading",
            class: "text-nowrap d-none d-lg-table-cell",
            Cell: (props) => (
              <div>{NumberFormat().format(props.value) + " kWh"}</div>
            ),
          },
          {
            Header: "Last Read Time 2",
            accessor: "lr2_gmt_timestamp",
            class: "text-nowrap d-none d-lg-table-cell",
            Cell: (props) => (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    <div>
                      {globalDateFormat(
                        props.value,
                        "YYYY-MM-DD  h:mm:ss A ",
                        site_tz
                      )}
                    </div>
                  </Tooltip>
                }
                color="black"
              >
                <div>
                  {props.value ? dayjs(props.value).format("YYYY-MM-DD") : ""}
                </div>
              </OverlayTrigger>
            ),
          },
          {
            Header: "Last Read 3",
            accessor: "lr3_reading",
            class: "text-nowrap d-none d-lg-table-cell",
            Cell: (props) => (
              <div>{NumberFormat().format(props.value) + " kWh"}</div>
            ),
          },
          {
            Header: "Last Read Time 3",
            accessor: "lr3_gmt_timestamp",
            class: "text-nowrap d-none d-lg-table-cell",
            Cell: (props) => (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    <div>
                      {globalDateFormat(
                        props.value,
                        "YYYY-MM-DD  h:mm:ss A ",
                        site_tz
                      )}
                    </div>
                  </Tooltip>
                }
                color="black"
              >
                <div>{dayjs(props.value).format("YYYY-MM-DD")}</div>
              </OverlayTrigger>
            ),
          },

    ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={`container-fluid`}>
      {props.readings_data.length > 0 && (
        <PrepareTable
          columns={columns}
          data={props.readings_data}
          isOnline={props.isOnline}
          refresh_table={props.refresh_table}
          image_count={props.image_count}
          handle_image_count_increase={props.handle_image_count_increase}
          handle_read_date_change={props.handle_read_date_change}
          manual_read_date={props.manual_read_date}
          set_manual_read_date={props.set_manual_read_date}
          handle_kwh_input_change={props.handle_kwh_input_change}
          set_manual_kwh_read={props.set_manual_kwh_read}
          manual_kwh_read={props.manual_kwh_read}
          handle_manual_reads_submit={props.handle_manual_reads_submit}
        />
      )}
    </div>
  );
}

function PrepareTable({
  columns,
  data,
  isOnline,
  refresh_table,
  image_count,
  handle_image_count_increase,
  handle_read_date_change,
  manual_read_date,
  set_manual_read_date,

  handle_kwh_input_change,
  set_manual_kwh_read,
  manual_kwh_read,
  handle_manual_reads_submit,
}) {
  const [show_readings_modal, set_show_readings_modal] = useState(false);
  const [row_data, set_row_data] = useState({});
  const [show_all, set_show_all] = useState(false);

  useEffect(() => {
    const update_storage = () => {
      // set_image_count(localStorage.length - 3);
    };
    window.addEventListener("storage", update_storage);
    return () => {
      window.removeEventListener("storage", update_storage);
    };
  }, [image_count]);
  // Use the useTable Hook to send the columns and data to build the table
  const handle_close_modal = () => {
    set_show_readings_modal(false);
  };

  const handle_show_all = () => {
    set_show_all(!show_all);
  };
  const handle_show_readings_modal = (e, props) => {
    set_show_readings_modal(true);
    set_row_data(props.original);
  };

  const handle_image_compress_submit = (data, gmt_timestamp) => {
    console.log(
      "ReadingsTable.js handle_image_compress_submit gmt_timestamp",
      gmt_timestamp
    );
    if (data) {
      console.log(
        "handle_image_compress_submit before compression",
        "filename=local_img_" +
          row_data.device_id +
          "_" +
          gmt_timestamp.toISOString(),
        data.size,
        data
      );

      //https://github.com/fengyuanchen/compressorjs/blob/main/README.md
      //Compress the new photo (camera or upload) to a JPEG file with EXIF data
      new Compressor(data, {
        quality: 9,
        // Chuck looked at height and width. Just set the height and let the width fall where ever it falls.
        // It does not crop the image. Just changes the pixel width to the setting below.
        height: 1000, //Will compress IF the image is taller than this
        //width: 1200, //Will compress IF the image is wider than this
        retainExif: true, //This works great
        success(result) {
          const reader = new FileReader();
          reader.readAsDataURL(result);
          reader.addEventListener("load", () => {
            console.log(
              "handle_image_compress_submit after compression",
              reader.result.length,
              reader
            );
            localStorage.setItem(
              "local_img_" +
                row_data.device_id +
                "_" +
                // new Date().toISOString(),
                gmt_timestamp.toISOString(),
              reader.result
            );
            handle_image_count_increase();
            handle_close_modal();
          });
        },
      });
    }
  };

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: ["sort_order", "need_ocr"],
    },
  });

  rows.sort((a, b) => a.values.sort_order - b.values.sort_order);

  return (
    <>
      <div className="table-responsive-md">
        {
          <div>
            {isOnline ? (
              <i className="fas fa-square" style={{ color: "green" }} />
            ) : (
                <Alert  variant="danger" className="text-center" >OFFLINE MODE</Alert>
            )}
            <span className="mx-3">{image_count}</span>
            {isMobile && (
              <span>
                <input
                  className="mx-2"
                  type="checkbox"
                  checked={show_all}
                  onChange={handle_show_all}
                />
                <label className="form-check-label">Show All</label>
              </span>
            )}
            {isOnline &&
              <button
                  className="btn btn-sm btn-primary mb-2 mx-3"
                  onClick={() => refresh_table()}
              >
                Refresh
              </button>
            }
          </div>
        }
        <table
          className={"table table-bordered table-hover"}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="text-center"
              >
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps({
                        className: column.class,
                      })}
                    >
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={(e) => handle_show_readings_modal(e, row)}
                  style={
                    row.original.need_ocr
                      ? isMobile && !show_all
                        ? { display: "none" }
                        : { textDecoration: "none" }
                      : { textDecoration: "none" }
                  }
                  className={
                  dayjs().diff(dayjs(row.original.lr_gmt_timestamp)) > 25  ? "reading_due":
                    row.original.need_ocr
                      ? "reading_ocr_required text-center"
                      : "text-center reading_good"
                  }
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className: cell.column.class,
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {isMobile ? (
        <ReadingsMobileModal
          show_readings_modal={show_readings_modal}
          handle_close_modal={handle_close_modal}
          row_data={row_data}
          handle_image_compress_submit={handle_image_compress_submit}
        />
      ) : (
        <ReadingsDesktopModal
          show_readings_modal={show_readings_modal}
          handle_close_modal={handle_close_modal}
          row_data={row_data}
          handle_image_compress_submit={handle_image_compress_submit}
          manual_read_date={manual_read_date}
          handle_read_date_change={handle_read_date_change}
          set_manual_read_date={set_manual_read_date}
          handle_kwh_input_change={handle_kwh_input_change}
          set_manual_kwh_read={set_manual_kwh_read}
          manual_kwh_read={manual_kwh_read}
          handle_manual_reads_submit={handle_manual_reads_submit}
        />
      )}
    </>
  );
}
export default ReadingsTable;
