import React, { Component } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { Fetch } from "../../helpers/Fetch";

class AdminReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collector_types: [],
      collectors:[],
      devices:[],
      devices_table:[],
      device_id:null
    };
  }
  componentDidMount() {
    this.get_collector_types() // @chuck I could also write the entire function here but it is cleaner written this way
  }

  get_collector_types=()=>{
    Fetch("admin_reports.php", {
      action: "get_collector_types",
      site_id: this.props.site_id,
    })
        .then((data) => {
          console.log(data);
          if (data.status === "ok") {
            this.setState({
              collector_types: data.data,
            });
          }
        })
        .catch((err) => console.log(err));
  }

  handleCollectorTypeChange=(e)=>{
    Fetch("admin_reports.php", {
      action: "get_collectors",
      site_id: this.props.site_id,
      collector_type_id:e.val

    })
        .then((data) => {
          console.log(data);
          if (data.status === "ok") {
            this.setState({
              collectors: data.data,
            });
          }
        })
        .catch((err) => console.log(err));
  }

  handleCollectorChange=(e)=>{
    Fetch("admin_reports.php", {
      action: "get_devices",
      collector_id:e.val
    })
        .then((data) => {
          console.log(data);
          if (data.status === "ok") {
            this.setState({
              devices: data.data,
            });
          }
        })
        .catch((err) => console.log(err));
  }

  handleDeviceChange=(e)=>{
    this.setState({
      device_id:e.val
    })
  }

  handleDeviceSave=(e)=>{
    const {device_id} = this.state
    Fetch("admin_reports.php", {
      action: "add_report_device",
      report_bill_id:this.props.report_bill_id,
      device_id:device_id,
      percent:100
    })
        .then((data) => {
          console.log(data);
          if (data.status === "ok") {
            this.props.handle_modal_close()
            this.props.reload_report_modal(e, this.props.report_bill_id, sessionStorage.getItem("admin_report_row_id"))
          }
        })
        .catch((err) => console.log(err));
  }

  render() {
    const { show_modal } = this.props;
    const { collector_types, collectors, devices, devices_table } = this.state;

    const device_options=devices.map((val)=>({
      val:val.device_id,
      label:val.device_name
    }))

    const collector_options = collectors.sort((a, b) => a.collector_name && a.collector_name.localeCompare(b.collector_name)).map((val)=>({
      val:val.collector_id,
      label:val.collector_name
    }))
    const collector_type_options = collector_types.sort((a, b) => a.device_type_name && a.device_type_name.localeCompare(b.device_type_name)).map((val) => ({
      val: val.collector_type_id,
      label: val.device_type_name,
    }));
    return (
      <div>
        <Modal
          show={show_modal}
          onHide={this.props.handle_modal_close}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Devices</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="collector_type"
              >
                <Form.Label>Collector Types</Form.Label>
                <Select options={collector_type_options} onChange={this.handleCollectorTypeChange}/>
              </Form.Group>

              {collector_options.length > 0 &&
                <Form.Group
                    className="mb-3"
                    controlId="collectors"
                >
                  <Form.Label>Collectors</Form.Label>
                  <Select options={collector_options} onChange={this.handleCollectorChange} />
                </Form.Group>
              }
              {device_options.length > 0 &&
                <Form.Group
                    className="mb-3"
                    controlId="device_options"
                >
                  <Form.Label>Devices</Form.Label>
                  <Select options={device_options} onChange={this.handleDeviceChange}  />
                </Form.Group>
              }
              {devices_table.length > 0 &&
              <Form.Group>
                <div>
                  <div className="row align-items-center">
                    <button className="btn btn-primary btn-sm offset-4 col-auto" onClick={this.handleAdminReportModalOpen}>Add Device</button>
                    <h4 className="col-auto">Devices Table</h4>
                  </div>
                  <table className="table">
                    <thead>
                    <tr>
                      <th>DID</th>
                      <th>D.Name</th>
                      <th>C.Name</th>
                      <th>D. Comm Data</th>
                      <th>D.Notes</th>
                      <th>Device Enabled</th>
                      <th>Alarm Enabled</th>
                      <th>D.Type Name</th>


                    </tr>
                    </thead>
                    <tbody>
                    {devices_table.map((val, index) =>
                        <tr key={index}>
                          <td>{val.device_id}</td>
                          <td>{val.device_name}</td>
                          <td>{val.collector_name}</td>
                          <td>{val.device_comm_data1}</td>
                          <td>{val.device_notes}</td>
                          <td><input type="checkbox" defaultChecked={val.enabled} /></td>
                          <td><input type="checkbox" defaultChecked={val.alarm_enabled}/></td>
                          <td>{val.device_type_name}</td>
                        </tr>)
                    }
                    </tbody>
                  </table>
                </div>
              </Form.Group>

              }

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleDeviceSave}>
              Save Changes
            </Button>
            <Button variant="secondary" onClick={this.props.handle_modal_close}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AdminReportModal;
