/**
 *
 * @param DecimalPlaces {integer} Number of decimal places
 * @param options {Object}  Intl.NumberFormat options object
 * @returns {Intl.NumberFormat}
 * @constructor
 *
 *
 * */

export function CurrencyFormat(DecimalPlaces = 0, options = {}) {
  /*
    if DecimalPlaces is not set, default to zero decimal places and append options to options object
    else if DecimalPlaces is set, append correct options to options object
     */

  //console.log("CurrencyFormat");

  //Check for valid inputs
  if (!isNaN(DecimalPlaces) && DecimalPlaces > 20)
    console.log(
      "ERROR: DecimalPlaces must be a number and maximum DecimalPlaces is 20!!",
      DecimalPlaces,
      options
    );

  let dp_options = {
    minimumFractionDigits: 2, //Always show atleast 2 zeros after DP
    maximumFractionDigits: DecimalPlaces ? DecimalPlaces : 2, //If set use value otherwise 0
  };

  //Append options to default options
  let op = null;
  if (options) op = Object.assign(options, dp_options);
  else op = dp_options;

  // console.log("CurrencyFormat ", op);

  return new Intl.NumberFormat(navigator.language, op);
}
