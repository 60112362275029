import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useTable, useSortBy, useExpanded } from "react-table";

import EditDevice from "./EditDevice";

import { Table, FormCheck, Tooltip, OverlayTrigger } from "react-bootstrap";

import { Fetch } from "../../helpers/Fetch";
import dayjs from "dayjs";
import { globalDateFormat } from "../../helpers/GlobalDateFormat";
import { NumberFormat } from "../../helpers/NumberFormat";

let duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

/**
 * This is a Functional Component, not a Class Component
 * https://www.twilio.com/blog/react-choose-functional-components
 * @param props
 * @returns {JSX.Element}
 */
export default function DeviceTable3(props) {
  // console.log("DeviceTable3 props", props);

  const [devices, setDevices] = useState([]);
  const [rowdata, setRowdata] = useState(null);

  const fetch_site_devices = function () {
    Fetch("devices_api.php", {
      action: "get_cid_devices",
      collector_id: props.row.collector_id,
    })
      .then((data) => {
        if (data && data.status === "ok") {
          console.log("Fetch data.devices", data.devices);
          setDevices(data.devices);
        } else {
          console.error("error", data);
        }
      })
      .catch((err) => console.error(err));
  };

  /**
   * Runs after mounting/rendering
   * and after a change of props.cid or state.refresh
   */
  useEffect(() => {
    // console.log("useEffect", state, props);

    fetch_site_devices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleEditDevice = function (status) {
    console.log("handleEditDevice modal status", status);

    /* Show modal when rowdata is not null
                                status will either be a click event (evaluates to true) or false
                                A click event means load rowdata and open the modal
                                false indicates that the modal has closed. Set rowdata to null.
                                 */

    if (status) {
      //Click event
      setRowdata(status.target.attributes.rowdata.value);
    } else {
      //return from modal
      setRowdata(null);
      fetch_site_devices();
    }
  };

  const toggleDeviceEnabled = function (e) {
    console.log("toggleDeviceEnabled", e.target.attributes.rowdata.value);

    let row = JSON.parse(e.target.attributes.rowdata.value);
    console.log("row", row);

    //Toggle enabled value
    row.enabled = !row.enabled;

    Fetch("devices_api.php", {
      action: "update_device",
      device: JSON.stringify(row), //Send
      updateCollector: "true", //Send true and false as text
    })
      .then((data) => {
        if (data && data.status === "ok") {
          console.log("Fetch data.devices", data);
          fetch_site_devices();
        } else {
          console.error("error", data);
        }
      })
      .catch((err) => console.error(err));
  };

  const GenerateAlarmIcon = function (props) {
    //console.log("props", props.row);
    const row = props.row;

    //Set defaults
    let st = { color: "black", cursor: "pointer" };
    let i = "fas fa-bell m-0";
    let t = null;
    let onClickFunc = null;

    if (row.device_type_id === 18) {
      //if PXMP point, no alarm config icon
      return null;
    } else {
      //console.log("row.ac_alarm_enabled", row);
      if (row.ac_alarm_enabled === null) {
        //No alarm configuration - black fa-bell-slash
        i = "fas fa-bell-slash ps-1 m-0";
        t = "No alarm configuration!\nClick to configure.";
        onClickFunc = handleEditDevice;
      } else {
        //Alarm configuration does exist
        i = "fas fa-bell ps-1 m-0";
        t = "Click to configure alarming.";

        if (row.enabled && row.ac_alarm_enabled && row.alarm_enabled) {
          //If both enabled settings are true, set icon color
          //If not, default to black bell
          if (!row.in_alarm) {
            //all good
            st = { color: "green", cursor: "pointer" };
          } else if (row.in_alarm && row.alarm_active && !row.alarm_acked) {
            //unacked alarm
            st = { color: "red", cursor: "pointer" };
          } else if (row.in_alarm && row.alarm_active && row.alarm_acked) {
            //active but acked
            st = { color: "yellow", cursor: "pointer" };
          } else if (row.in_alarm && !row.alarm_active && !row.alarm_acked) {
            //non-active and unacked
            st = { color: "blue", cursor: "pointer" };
          }
        }
      }
      return (
        <i
          onClick={onClickFunc}
          rowdata={JSON.stringify(row)}
          title={t}
          className={i}
          style={st}
        ></i>
      );
    }
  };

  const memoDevices = useMemo(() => devices, [devices]);

  const columns = useMemo(
    () => [
      {
        accessor: "enabled",
        // c_className: "col",
        Cell: (props) => {
          // console.log("props", props);
          return (
            <Fragment>
              <FormCheck
                onChange={toggleDeviceEnabled}
                rowdata={JSON.stringify(props.row.original)}
                className="m-0 pe-1"
                // disabled
                inline
                title="Toggle device enabled."
                checked={props.value}
              />
              <i
                onClick={handleEditDevice}
                rowdata={JSON.stringify(props.row.original)}
                className="fas fa-edit m-0"
                title="Click to edit device configuration."
                style={{ cursor: "pointer" }}
              ></i>
              <GenerateAlarmIcon row={props.row.original}></GenerateAlarmIcon>
            </Fragment>
          );
        },
      },
      {
        id: "tree_id",
      },
      {
        id: "device_id",
        accessor: "device_id",
        Header: "DID",
      },
      {
        id: "device_name",
        accessor: "device_name",
        Header: "Device Name",
        // width: 500,
      },
      {
        id: "designation",
        accessor: "designation",
        Header: "Designation",
        // width: 500,
      },
      {
        accessor: "device_type_name",
        Header: "Device Type",
        c_className: "d-none d-lg-table-cell", //hide on screens smaller than lg
      },
      {
        accessor: "gmt_last_read",
        Header: "Last Read",
        // c_className: "col-2",
        Cell: (props) => {
          const cellVal = props.cell.value;
          //console.log("gmt_last_contact props", x, props);

          const d = dayjs().unix() - dayjs.utc(cellVal).unix();
          let f;
          if (d < 60)
            //if < 1 minute
            f = "s[s] [ago]";
          else if (d < 3600 * 1)
            //if < 1 hour - Normal
            f = "m[m] s[s] [ago]";
          else if (d < 3600 * 24)
            //if < 24 hours - Late
            f = "H[h] m[m] s[s] [ago]";
          else return globalDateFormat(cellVal, "L HH:mm z") +" (hrs)"; //very late - show date

          //Return formatted duration
          return dayjs.duration(d, "seconds").format(f);
        },
      },
      {
        accessor: "last_read",
        Header: <span className="float-end">Last Read</span>,
        // c_className: "col-2",
        Cell: (props) => {
          // console.log("props", props);
          return props.row.original.default_param_id ? (
            <span
              className="float-end"
              title={
                "Last read: " +
                globalDateFormat(props.row.original.gmt_last_read)
              }
            >
              {NumberFormat().format(props.value) +
                " " +
                props.row.original.default_param_units}
            </span>
          ) : (
            <span className="float-end">No default</span>
          );
        },
      },
      {
        accessor: "last_7_days",
        Header: <span className="float-end">Last 7 days</span>,
        c_className: "d-none d-lg-table-cell", //hide on screens smaller than lg
        Cell: (props) => {
          // console.log("props", props);
          return props.row.original.default_param_id ? (
            <span className="float-end">
              {NumberFormat().format(props.value) +
                " " +
                props.row.original.default_param_units}
            </span>
          ) : (
            <span className="float-end"></span>
          );
        },
      },
      {
        accessor: "last_28_days",
        Header: <span className="float-end">Last 28 days</span>,
        c_className: "d-none d-lg-table-cell", //hide on screens smaller than lg
        Cell: (props) => {
          // console.log("props", props);
          return props.row.original.default_param_id ? (
            <span className="float-end">
              {NumberFormat().format(props.value) +
                " " +
                props.row.original.default_param_units}
            </span>
          ) : (
            <span className="float-end"></span>
          );
        },
      },
    ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // CRITICAL: This must be here - The page will break otherwise
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // visibleColumns,
    // state: { expanded },
  } = useTable(
    {
      columns,
      data: memoDevices,
      autoResetExpanded: false,
      initialState: {
        hiddenColumns: ['tree_id'],
        sortBy: [
          {
            id: "tree_id",
            desc: false,
          },
          {
            id: "device_name",
            desc: false,
          },
        ],
      },
    },
    useSortBy,
    useExpanded
  );

  console.count("DeviceTable3 render", devices);
  return (
    <div
      className="DeviceTable col-xl-12 col-xxl-10 mx-auto"
      style={{
        maxHeight: "1000px",
        overflow: "auto",
        display: "inlineBlock",
      }}
    >
      <Table //Bootstrap table settings
        //bordered
        size="sm"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps({
                      className: column.c_className,
                    })}
                  >
                    {column.render("Header")}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {/* Process collector rows */}
          {rows.map((row, i) => {
            prepareRow(row);

            const rd = row.original; //row data
            // console.log("prepared row", row, rd);
            return (
              <Fragment key={"idx_" + row.id}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      <div>DID: {rd.device_id}</div>
                      <div>Type: {rd.device_type_name}</div>
                      <div>Comm: {rd.device_comm_data1}</div>
                      <div>
                        Type ID: {rd.device_type_id} Tree ID: {rd.tree_id}
                      </div>
                      {rd.device_notes ? (
                        <Fragment>
                          <div>Notes</div>
                          <div
                            style={{
                              borderStyle: "solid",
                              borderWidth: "1px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {rd.device_notes}
                          </div>
                        </Fragment>
                      ) : null}
                    </Tooltip>
                  }
                  color="black"
                >
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      // console.log("cell", cell);
                      return (
                        <td
                          {...cell.getCellProps({
                            className: cell.column.c_className,
                          })}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </OverlayTrigger>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      <EditDevice handleModalClose={handleEditDevice} rowdata={rowdata} />
    </div>
  );
}
