import {Component} from 'react';
import AlarmRow from "./AlarmRow";


class AlarmTable extends Component {
    render() {
        console.log('*** AlarmTable render ****')
        const { alarm_data, handleCheckBoxChange, checked_alarms } = this.props
        const rows = [];

        alarm_data && alarm_data.forEach((alarm, index)=>{
            rows.push(
                <AlarmRow
                    key={index}
                    alarm_row={alarm}
                    handleCheckboxChange={handleCheckBoxChange}
                    checked_alarms={checked_alarms}
                />
            )
        })


        return (
            <table className="table table-bordered table-responsive table-hover table-sm" id="alarm_table">
                <thead>
                <tr>
                    <th className="d-none d-lg-table-cell"></th>
                    <th className="text-center d-none d-lg-table-cell">Alarm Occurred</th>
                    <th className="text-center d-none d-lg-table-cell">Alarm Ack'd</th>
                    <th className="text-center">Last contact/read</th>
                    <th className="text-center">Location</th>
                    <th className="text-center">Device Name</th>
                    <th className="text-center">Device Type</th>
                    <th className="text-center d-none d-lg-table-cell">Alarm Type</th>
                </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        );
    }
}

export default AlarmTable;