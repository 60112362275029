import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import "./Reading.css";
import "swiper/swiper-bundle.min.css";
import { Fetch } from "../../helpers/Fetch";
import dayjs from "dayjs";

export default function ReadingsMobileModal(props) {
  const site_tz = sessionStorage.getItem("prev_site_tz");
  const [show_modal, set_show_modal] = useState(false);
  const [image_ideal, set_image_ideal] = useState(props.ideal_image_url);
  const [image_taken, set_image_taken] = useState("");
  const [file, set_file] = useState();
  const [show_ideal_img, set_show_ideal_img] = useState(false);

  const { handleSubmit } = useForm();

  useEffect(() => {
    //Runs when model is opened
    set_show_modal(props.show_readings_modal);
    if (props.show_readings_modal) {
      show_ideal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show_readings_modal]);

  const show_ideal = () => {
    Fetch(`Reader_api.php`, {
      action: "get_ideal_image",
      device_id: props.row_data.device_id,
    })
      .then((data) => {
        if (data.status === "ok") {
          set_image_ideal(data.presigned_picture);
          set_show_ideal_img(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onSubmit = (data) => {
    props.handle_image_compress_submit(
      file,
      dayjs(props.manual_read_date, site_tz).utc()
    ); //send time in UTC
    set_image_ideal("");
    set_image_taken("");
    props.handle_close_modal();
  };

  const handle_show_ideal = (e) => {
    e.preventDefault();
    set_show_ideal_img(!show_ideal_img);
  };

  const handleCloseModal = () => {
    if (image_taken.length > 0) {
      if (
        window.confirm(
          "Are you sure you want to close you will loose your work"
        )
      ) {
        props.handle_close_modal();
        set_image_taken("");
        set_image_ideal("");
      }
    } else {
      props.handle_close_modal();
      set_image_taken("");
      set_image_ideal("");
    }
  };

  const handleImageChange = (e) => {
    set_file(e.target.files[0]);
    set_show_ideal_img(false);
    set_image_taken(window.webkitURL.createObjectURL(e.target.files[0]));
  };

  console.log("ReadingsMobileModal render return");
  return (
    <Modal
      show={show_modal} //this can be true because we are checking for data above
      // onHide={() => setData(null)} //When hiding, clear out data
      backdrop="static" //User must press cancel or submit to hide modal
      keyboard={false} //User must press cancel or submit to hide modal
      size="md" //Large modal
      fullscreen="md-down"
      onHide={props.handle_close_modal}
    >
      <Modal.Header className="readings-header">
        <Modal.Title>
          <div className="text-center">
            <h2>{props.row_data.org_name}</h2>
            <h4>{props.row_data.device_name}</h4>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)} className="col-12">
          <div className="col-12 text-center">
            {show_ideal_img ? (
              image_ideal ? (
                <img
                  className="col-12"
                  style={{ height: "400px", width: "auto", maxHeight: "100%" }}
                  alt="meter_img_ideal"
                  src={image_ideal}
                />
              ) : (
                <div style={{ height: "400px" }}>
                  <h4
                    style={{
                      verticalAlign: "middle",
                      position: "relative",
                      top: "50%",
                    }}
                    className="text-center"
                  >
                    Ideal Image Not set
                  </h4>
                </div>
              )
            ) : image_taken ? (
              <img
                className="col-12"
                style={{ height: "N:Nn400px", width: "auto", maxHeight: "100%" }}
                alt="meter_img"
                src={image_taken}
              />
            ) : (
              <div style={{ height: "400px" }}>
                <h4
                  style={{
                    verticalAlign: "middle",
                    position: "relative",
                    top: "50%",
                  }}
                  className="text-center"
                >
                  Take A Reading Photo
                </h4>
              </div>
            )}
          </div>

          <div className="row mt-2 mb-2 text-center">
            {image_taken ? (
              <div className="col-6">
                <button
                  onClick={handle_show_ideal}
                  className="btn btn-sm btn-primary"
                >
                  {show_ideal_img ? "Show Current Photo" : "Show Ideal Photo"}
                </button>
              </div>
            ) : (
              ""
            )}

            <div className="col-5 offset-1">
              <input
                style={{ display: "none" }}
                id="imageUpload"
                size="sm"
                type="file"
                accept="image/*"
                name="reading_image"
                onChange={handleImageChange}
                capture="user"
              />
              <label
                className="text-center btn btn-sm btn-primary"
                htmlFor="imageUpload"
                style={{ whiteSpace: "nowrap" }}
                onChange={handleImageChange}
              >
                {image_taken.length > 0 ? "Retake Photo" : "Take Photo"}
                <i className="fas fa-camera" style={{ marginLeft: "10px" }} />
              </label>
            </div>
          </div>

          <div className="row mb-3">
            <textarea
              rows={4}
              disabled
              style={{ fontStyle: "italic", width: "250%" }}
              defaultValue={props.row_data.device_notes}
            ></textarea>
          </div>
          <div className="float-end">
            <Button className="btn-sm btn-secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={image_taken.length < 1}
              className="mx-2 btn-sm btn-primary"
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
