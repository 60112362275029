import React, { Component } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useTable } from "react-table";
import dayjs from "dayjs";

import "./Invoices.css";

class ReportQueue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report_queue_data: [],
    };
  }

  componentDidMount() {
    this.setState({
      report_queue_data: this.props.report_queue_data,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.report_queue_data !== this.props.report_queue_data) {
      this.setState({
        report_queue_data: this.props.report_queue_data,
      });
    }
  }
  render() {
    const { site_tz } = this.props;
    console.log(site_tz);
    const columns = [
      {
        Header: "Requested By",
        accessor: "requestor",
        key: "requestor",
      },
      {
        Header: <div style={{ textAlign: "center" }}>Run Seconds</div>,
        accessor: "run_seconds",
        key: "run_seconds",
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>{props.cell.value}</div>
        ),
      },
      {
        Header: "Site Name",
        accessor: "site_name",
        key: "site_name",
      },
      {
        Header: <div style={{ textAlign: "center" }}>Run Completed</div>,
        accessor: "run_completed",
        key: "run_completed",
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {props.cell.value
              ? dayjs.utc(props.cell.value).local().format("YYYY-MM-DD HH:mm")
              : ""}
          </div>
        ),
      },
      {
        Header: <div style={{ textAlign: "center" }}>Date Effective</div>,
        accessor: "date_effective",
        key: "date_effective",
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {dayjs
              .utc(props.cell.value)
              .tz(props.cell.row.original.site_tz)
              .format("YYYY-MM-DD HH:mm")}
          </div>
        ),
      },
      {
        Header: <div style={{ textAlign: "center" }}>Date End</div>,
        accessor: "date_end",
        key: "date_end",
        Cell: (props) => (
          <div style={{ textAlign: "center" }}>
            {dayjs
              .utc(props.cell.value)
              .tz(props.cell.row.original.site_tz)
              .format("YYYY-MM-DD HH:mm")}
          </div>
        ),
      },
      {
        accessor: "running",
        key: "running",
      },
      {
        accessor: "command",
        key: "command",
      },
    ];

    function TableRender({ columns, data }) {
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({
        columns,
        data,
        initialState: {
          hiddenColumns: ["running", "command"],
        },
      });

      rows.sort((a, b) => a.values.device_id - b.values.device_id);

      return (
        <div>
          <Table //Bootstrap table settings
            bordered
            hover
            responsive
            size="sm"
            // variant="dark"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr key={"header"} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th key={column.key} {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <React.Fragment key={i}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          <div>{row.values.command}</div>
                        </Tooltip>
                      }
                      color="black"
                    >
                      <tr
                        className={
                          row.values.running
                            ? "report_queue_running"
                            : row.values.run_completed
                            ? ""
                            : "report_queue_pending"
                        }
                        key={row.values.device_id}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    </OverlayTrigger>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      );
    }

    const { report_queue_data } = this.state;

    return (
      <div>
        <div className="mt-3">
          {report_queue_data.length > 0 && (
            <TableRender columns={columns} data={report_queue_data} />
          )}
        </div>
      </div>
    );
  }
}

export default ReportQueue;
