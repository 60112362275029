import React, { Component, useState } from "react";
import { usePagination, useRowSelect, useTable } from "react-table";
import { Button, Modal, Table, Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";

import { Fetch } from "../../helpers/Fetch";

class AdminAlarmsTable extends Component {
  render() {
    const columns = [
      {
        Header: () => (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                <strong>Alarm Config ID</strong>
              </Tooltip>
            }
          >
            <div>AC_ID</div>
          </OverlayTrigger>
        ), //Text of the header
        accessor: "alarm_config_id", //Property name in data
        key: "alarm_config_id",
      },
      {
        Header: () => <div className="me-5">Alarm Name</div>,
        accessor: "alarm_name",
        key: "alarm_name",
        class: "w-50",
      },
      {
        Header: () => <div className="me-5">Alarm Type</div>,
        accessor: "alarm_type",
        key: "alarm_type",
        class: "w-50 d-none d-md-table-cell",
      },
    ];
    const IndeterminateCheckbox = React.forwardRef(
      ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
          resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
          <>
            <input type="checkbox" ref={resolvedRef} {...rest} />
          </>
        );
      }
    );

    function TableRender({ columns, data, edited_user_id, alarm_group_id }) {
      console.log(columns, data, edited_user_id);
      const handleSelectedRows = () => {
        let selected_ids = {};
        data.forEach((val, index) => {
          if (val.sel_by_default === true) {
            selected_ids[index] = true;
          }
        });
        return selected_ids;
      };

      console.log(handleSelectedRows());

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
      } = useTable(
        {
          columns,
          data,
          autoResetExpanded: false,
          autoResetPageIndex: false,
          resetPageOnDataChange: false,

          initialState: {
            pageIndex: 0,
            pageSize: 50,
            selectedRowIds: handleSelectedRows(),
          },
        },
        usePagination,
        useRowSelect,
        (hooks) => {
          hooks.visibleColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox

              //True but we would need to do the checkbox selection ourselves ie. the Functionality
              //This is the top th checkbox that checks all the data in the table
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              //For individual rows its okay but we want to copy multiple rows
              //This is the checkbox for individual rows
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
              width: 25,
            },
            ...columns,
          ]);
        }
      );
      const [showAssignModal, setAssignModal] = useState(false);
      const handleCloseAssignModal = () => setAssignModal(false);
      const [enableAssignButton, setAssignButton] = useState(false);
      const [selected_row_id, set_selected_row_id] = useState(0);
      const [AssignData, setAssignData] = useState([]);
      const [adminAlarms, setAdminAlarms] = useState([]);
      const handleShowAssignModal = (e, val) => {
        setAssignModal(true);
        setAssignButton(false);
        setAssignData(val);
      };

      const handleBatchAddAlarms = () => {
        //Create array of device or collector ids to send to server
        let acid_array = [];
        AssignData.forEach((r) => {
          acid_array.push(r.id);
        });

        Fetch(`admin_alarm.php`, {
          action: "assign_admin_alarms",
          edited_user_id: edited_user_id,
          alarm_rows: acid_array, //
          alarm_group_id: alarm_group_id,
        }).then((data) => {
          console.log(data);
          setAssignModal(false);
        });
      };

      const getAssignedAlarms = (val) => {
        Fetch(`admin_alarm.php`, {
          action: "get_assigned_alarms",
          selected_alarm_group_id: val,
        }).then((data) => {
          console.log(data);
          setAdminAlarms(data.data);
          set_selected_row_id(val);
        });
      };

      console.log(AssignData);
      console.log(selected_row_id);

      return (
        <>
          <div>
            {selectedFlatRows.length > 0 && (
              <button
                className="btn btn-sm btn-primary mx-2 mb-2"
                onClick={(e) =>
                  handleShowAssignModal(
                    e,
                    selectedFlatRows.map((d) => d.original)
                  )
                }
              >
                Assign Alarms To User
              </button>
            )}
          </div>
          <Modal
            show={showAssignModal}
            onHide={handleCloseAssignModal}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>User Alarms Assign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Are you sure you want to Add selected Alarm groups</h4>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-no-wrap">A. C. ID</th>
                    <th>Alarm Name</th>
                    <th>Alarm Type</th>
                  </tr>
                </thead>
                <tbody>
                  {AssignData.map((val, index) => (
                    <tr key={index}>
                      <td>{val.alarm_config_id}</td>
                      <td>{val.alarm_name}</td>
                      <td>{val.alarm_type}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseAssignModal}>
                Cancel
              </Button>
              <Button
                variant="danger"
                disabled={enableAssignButton}
                onClick={handleBatchAddAlarms}
              >
                Assign Alarms
              </Button>
            </Modal.Footer>
          </Modal>
          <Table //Bootstrap table settings
            bordered
            hover
            size="sm"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr key={"header"} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      key={column.key}
                      {...column.getHeaderProps({
                        class: column.class,
                      })}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                //Loop though the rows
                prepareRow(row);

                return (
                  <React.Fragment key={i}>
                    <tr
                      key={row.values.site_id}
                      {...row.getRowProps()}
                      onClick={() =>
                        getAssignedAlarms(row.values.alarm_group_id)
                      }
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              class: cell.column.class,
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>

          {adminAlarms && adminAlarms.length > 0 && (
            <div>
              <h5 className="text-center">Alarm Devices </h5>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Alarm Type</th>
                    <th>Alarm Name</th>
                    <th>Alarm Type</th>
                  </tr>
                </thead>
                <tbody>
                  {adminAlarms.map((val, index) => (
                    <tr key={index}>
                      <td>{val.alarm_type}</td>
                      <td>{val.alarm_name}</td>
                      <td>{val.alarm_type}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      );
    }
    return (
      <div>
        <TableRender
          columns={columns}
          data={this.props.data}
          edited_user_id={this.props.edited_user_id}
          alarm_group_id={this.props.alarm_group_id}
        />
      </div>
    );
  }
}

export default AdminAlarmsTable;
