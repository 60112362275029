import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import ImageZoom from "react-image-zooom";
import "./Reading.css";

import { Fetch } from "../../helpers/Fetch";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { NumberFormat } from "../../helpers/NumberFormat";
import { globalDateFormat } from "../../helpers/GlobalDateFormat";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function ReadingsDesktopModal(props) {
  const [show_modal, set_show_modal] = useState(false);
  const [image_ideal, set_image_ideal] = useState("");

  const [image_taken, set_image_taken] = useState("");
  // const [site_tz, set_site_tz] = useState(
  //   sessionStorage.getItem("prev_site_tz")
  // );
  const site_tz = sessionStorage.getItem("prev_site_tz");
  const [show_ideal_img, set_show_ideal_img] = useState(false);
  const [file, set_file] = useState({});
  const [image_count, set_image_count] = useState(0);
  const [offset, set_offset] = useState(0);
  const [image_data, set_image_data] = useState("");
  const { handleSubmit } = useForm();

  useEffect(() => {
    //Runs when modal opens and closes - Change in the value of props.show_readings_modal
    console.log(
      "ReadingsDesktopModal Read useEffect props",
      props.show_readings_modal
    );

    //If the modal is opening
    if (props.show_readings_modal) {
      if (props.row_data.device_id) {
        get_image_count();
        get_device_image();
        set_offset(0); //reset when modal opens

        //Set to current local time by default
        props.set_manual_read_date(dayjs().format("YYYY-MM-DDTHH:mm:ss"));
        props.set_manual_kwh_read("");
        set_show_ideal_img(false);
      }

      set_image_ideal(props.ideal_image_url);
      set_show_modal(true);
    } else set_show_modal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show_readings_modal]);

  //used to specifically update get_image for new image on pan accepted by react docs(https://stackoverflow.com/questions/56247433/how-to-use-setstate-callback-on-react-hooks)
  useEffect(() => {
    console.log("offset change", offset);
    if (props.row_data.device_id) {
      get_device_image();
    }
    // eslint-disable-next-line
  }, [offset]);

  const get_image_count = () => {
    Fetch(`Reader_api.php`, {
      action: "get_image_count",
      device_id: props.row_data.device_id,
    })
      .then((data) => {
        set_image_count(data.image_count);
        //console.log("Image count", props.row_data.device_id, data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const get_device_image = () => {
    Fetch(`Reader_api.php`, {
      action: "get_device_image",
      device_id: props.row_data.device_id,
      offset: offset,
    })
      .then((data) => {
        if (data.status === "ok") {
          set_image_data(data.image);
          props.set_manual_read_date(data.image.gmt_timestamp);
          if (data.image.log_value) {
            props.set_manual_kwh_read(data.image.log_value);
          } else {
            props.set_manual_kwh_read("");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const clear_ocr = (e) => {
    e.preventDefault();
    Fetch(`Reader_api.php`, {
      action: "clear_ocr",
      device_id: props.row_data.device_id,
    })
      .then((data) => {
        if (data.status === "ok") {
          console.log("OCR cleared");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const show_ideal = (e) => {
    e.preventDefault();
    Fetch(`Reader_api.php`, {
      action: "get_ideal_image",
      device_id: props.row_data.device_id,
    })
      .then((data) => {
        if (data.status === "ok") {
          set_image_ideal(data.presigned_picture);
          set_show_ideal_img(!show_ideal_img);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const make_ideal = (e) => {
    e.preventDefault();
    Fetch(`Reader_api.php`, {
      action: "make_ideal_image",
      device_id: props.row_data.device_id,
      image_filename: image_data.image_filename,
      file_mime_type: image_data.file_mime_type,
      gmt_timestamp: image_data.gmt_timestamp,
    })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const delete_image = (e) => {
    e.preventDefault();
    Fetch(`Reader_api.php`, {
      action: "delete_image",
      device_id: props.row_data.device_id,
      image_filename: image_data.image_filename,
    })
      .then((data) => {
        console.log(data, offset, image_count);
        if (offset + 1 === image_count) {
          //deleted the last image
          set_offset(offset - 1);
        }
        get_image_count();
        get_device_image();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onSubmit = () => {
    console.log(
      "ReadingsDesktopModal form onSubmit",
      props.manual_kwh_read,
      props.manual_read_date,
      Object.keys(file).length
    );

    console.log("image_data", image_data);
    console.log("image_taken", image_taken);

    // console.log(
    //   file,
    //   "TZ convert",
    //   site_tz,
    //   dayjs(props.manual_read_date, site_tz).utc().format()
    // );

    /*
                                    This is the desktop, so we can count on internet access
                                    The manual_read_date is in the TZ of the site, so it needs to be converted to UTC
                
                                    There are multiple conditions to arrive here after the user presses submit:
                                    1 - User presses submit but does not do anything.
                                             image_taken empty
                                             props.manual_kwh_read empty
                                             DO NOTHING
                                    2 - User takes a photo
                                             image_taken populated
                                             Compress image
                                     3 - User takes a photo and enters kwh reading
                                             Send device_id, props.manual_kwh_read, and props.manual_read_date to API
                                             Compress image
                                     4 - User enters kWh reading only
                                             Send device_id, props.manual_kwh_read, and props.manual_read_date to API
                                     */

    if (props.manual_kwh_read) {
      //If a kWh reading was provided, send to API
      console.log(
        "FETCH",
        image_taken.length,
        props.row_data.device_id,
        image_taken.length > 0
          ? image_data.gmt_timestamp
          : props.manual_read_date,
        props.manual_kwh_read
      );

      Fetch(`Reader_api.php`, {
        action: "upload_manual_reading",
        device_id: props.row_data.device_id,
        gmt_timestamp:
          image_taken.length > 0
            ? dayjs.utc(image_data.gmt_timestamp)  //Local time
            : props.manual_read_date, //UTC time
        reading: props.manual_kwh_read,
        param_id: 10,
      })
        .then((data) => {
          set_image_count(data.image_count);
          //console.log("Image count", props.row_data.device_id, data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    //If there is a new image/file, compress and put in localStorage
    if (image_taken)
      props.handle_image_compress_submit(
        file,
        dayjs(props.manual_read_date, site_tz).utc() //send time in UTC
      );

    set_image_ideal("");
    set_image_taken("");
    props.handle_close_modal();
  };
  const handleCloseModal = () => {
    props.handle_close_modal();
    set_image_ideal("");
    set_image_taken("");
  };

  const handleImageChange = (e) => {
    //run when a new image is uploaded
    set_file(e.target.files[0]);
    console.log("e.target.files", e.target.files);
    set_image_taken(window.webkitURL.createObjectURL(e.target.files[0]));
    set_image_data("");
  };
  const handleOffsetForward = (e) => {
    //Pan image forward/back in time
    e.preventDefault();
    if (offset <= image_count) {
      set_offset(offset + 1);
    }
  };

  const handleOffsetBack = (e) => {
    //Pan image backward/forward in time
    e.preventDefault();
    if (offset >= 0) {
      set_offset(offset - 1);
    }
  };

  const handleOffsetStart = (e) => {
    e.preventDefault();
    set_offset(0);
  };

  //console.log("ReadingsDesktopModal return", props, image_data, image_taken);

  //start here
  //console.log("ReadingsDesktopModal render offset=", offset, image_data);
  return (
    <Modal
      show={show_modal} //this can be true because we are checking for data above
      // onHide={() => setData(null)} //When hiding, clear out data
      keyboard={true} //Escape key will hide modal
      size="lg" //Large modal on desktop
      // fullscreen="md-down"  //Not needed any longer for desktop modal
      onHide={props.handle_close_modal}
    >
      <Modal.Header className="readings-header">
        <Modal.Title>
          <div className="text-center">
            <h4>
              {props.row_data.org_name + " - " + props.row_data.device_name}{" "}
            </h4>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* The col-12 got the form to center in the modal*/}
        <Form onSubmit={handleSubmit(onSubmit)} className="col-12">
          <div className="col-12 text-center">
            {image_taken || image_count > 0 ? (
              show_ideal_img ? (
                image_ideal ? (
                  <ImageZoom
                    height="400px"
                    src={image_ideal && image_ideal}
                    alt="meter_img"
                  />
                ) : (
                  <div style={{ height: "400px" }}>
                    <h4
                      style={{
                        verticalAlign: "middle",
                        position: "relative",
                        top: "50%",
                      }}
                      className="text-center"
                    >
                      Ideal Image Not set
                    </h4>
                  </div>
                )
              ) : image_taken ? (
                <ImageZoom
                  height="400px"
                  key={image_taken}
                  src={image_taken ? image_taken : "img/spinner.gif"}
                  alt="meter_img"
                />
              ) : image_data && image_data !== "none" ? (
                <ImageZoom
                  height="400px"
                  key={image_data && image_data.presignedUrl}
                  src={image_data ? image_data.presignedUrl : "img/spinner.gif"}
                  alt="meter_img"
                />
              ) : (
                <div style={{ height: "400px" }}>
                  <h4
                    style={{
                      verticalAlign: "middle",
                      position: "relative",
                      top: "50%",
                    }}
                    className="text-center"
                  >
                    No Images Uploaded
                  </h4>
                </div>
              )
            ) : (
              <div style={{ height: "400px" }}>
                <h4
                  style={{
                    verticalAlign: "middle",
                    position: "relative",
                    top: "50%",
                  }}
                  className="text-center"
                >
                  No Images Uploaded
                </h4>
              </div>
            )}
          </div>
          <div>
            {image_taken ? (
              // {props.manual_read_date.length > 0 || image_taken ? (
              <div className="text-center">
                To pan other images, submit this new image first.
              </div>
            ) : (
              <div className="col-12 text-center">
                <button
                  disabled={offset === 0}
                  onClick={handleOffsetStart}
                  className="btn btn-sm"
                >
                  <span>
                    <i className="fa fa-grip-lines-vertical" />
                    <i className="fa fa-chevron-left" />
                    <i className="fa fa-chevron-left" />
                  </span>
                </button>
                <button
                  disabled={offset === 0}
                  onClick={handleOffsetBack}
                  className="btn btn-sm"
                >
                  <i className="fa fa-chevron-left" />
                </button>
                <span>{offset + 1}</span>
                <button
                  disabled={offset >= image_count - 1}
                  onClick={handleOffsetForward}
                  className="btn btn-sm"
                >
                  <i className="fa fa-chevron-right" />
                </button>
                {/*<div className="small">*/}
                {/*  {image_data && image_data.image_filename}*/}
                {/*</div>*/}
              </div>
            )}

            <div className="col-8">
              <input
                style={{ display: "none" }}
                id="imageUpload"
                type="file"
                accept="image/*"
                name="reading_image"
                onChange={handleImageChange}
                capture="user"
              />
            </div>

            <div className="col-12 text-center">
              <button
                disabled={image_taken || image_count === 0}
                onClick={make_ideal}
                className="btn btn-primary btn-sm me-2"
              >
                Make Ideal
              </button>
              <label
                className="text-center btn btn-sm btn-primary me-2"
                htmlFor="imageUpload"
                style={{ whiteSpace: "nowrap" }}
                // onChange={handleImageChange}
              >
                Upload Photo
                <i className="fas fa-camera" style={{ marginLeft: "10px" }} />
              </label>
              <button
                onClick={show_ideal}
                className="btn btn-primary btn-sm me-2"
              >
                {show_ideal_img ? "Hide" : "Show"} Ideal
              </button>
              <button
                disabled={image_taken || image_count === 0}
                onClick={delete_image}
                className="btn btn-danger btn-sm me-2"
              >
                Delete Photo
              </button>
              <small>(count:{image_count})</small>
            </div>

            <div className="m-3 text-center row">
              <div className="col-6">
                <label htmlFor="reading">
                  <i className="fa fa-plug me-2" aria-hidden="true"></i>
                  New kWh reading :
                </label>
                <input
                  id="reading"
                  type="number"
                  onChange={props.handle_kwh_input_change}
                  value={props.manual_kwh_read}
                  className="form-control"
                ></input>
              </div>
              <div className="form-group col-6">
                <label htmlFor="readingDate">
                  <i className="fa fa-calendar me-2" aria-hidden="true"></i>
                  Reading Date <small>({site_tz})</small> :
                </label>
                <input
                  id="readingDate"
                  type="datetime-local"
                  disabled={!image_taken && image_data && image_data.mobile_ts}
                  className="form-control"
                  onChange={props.handle_read_date_change}
                  // value={
                  //   image_data
                  //     ? globalDateFormat(
                  //         image_data.gmt_timestamp,
                  //         "YYYY-MM-DDTHH:mm:ss",
                  //         site_tz
                  //       )
                  //     : dayjs(props.manual_read_date).format(
                  //         "YYYY-MM-DDTHH:mm:ss"
                  //       )
                  // }
                  value={dayjs(props.manual_read_date).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )}
                  name="dateRequired"
                />
              </div>
              {/*
              example implementation
              */}
              {/*<div className="col-1" style={{ marginTop: "28px" }}>*/}
              {/*  <button*/}
              {/*    className="btn btn-sm btn-primary"*/}
              {/*    // disabled={props.manual_kwh_read || props.manual_kwh_read ? false : false}*/}
              {/*    onClick={props.handle_manual_reads_submit}*/}
              {/*  >*/}
              {/*    Submit{" "}*/}
              {/*  </button>*/}
              {/*</div>*/}
            </div>

            <div className="row col-10 mx-auto">
              <input
                type="text"
                defaultValue={
                  "Previous: " +
                  NumberFormat().format(props.row_data.lr_reading) +
                  " kWh @" +
                  globalDateFormat(
                    props.row_data.lr_gmt_timestamp,
                    "YYYY-MM-DD  h:mm:ss A",
                    site_tz
                  )
                }
                disabled
                className="form-control mt-2 text-center"
              ></input>
            </div>
          </div>
          <div className="row col-10 mx-auto mt-2">
            <textarea
              name="device_notes"
              className="form-control"
              rows={4}
              disabled
              defaultValue={props.row_data.device_notes}
            ></textarea>
          </div>
          <div className="float-end mt-3">
            <button onClick={clear_ocr} className="mx-2 btn btn-warning btn-sm">
              Clear OCR
            </button>

            <Button className="btn-sm btn-secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              // disabled={image_taken.length < 1}
              type="submit"
              className="mx-2 btn-sm btn-primary"
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
