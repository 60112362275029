import { Component } from "react";
import Select from "react-select";
import { Fetch } from "../../helpers/Fetch";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { LogUserAction } from "../../helpers/LogUserAction";

class SiteSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterText: "",
    };
  }
  componentDidMount() {
    this.getSiteData(); //Get from server
  }

  render() {
    //If no data, return now
    if (!this.site_options) return null;

    //Sort the option alphabetically
    this.site_options.sort((a, b) => (a.label > b.label ? 1 : -1));

    // console.log("site_options", this.site_options);

    //Get the previous site_id if there is one
    //Using sessionStorage to survive a browser refresh
    const prev_site_id = sessionStorage.getItem("prev_site_id");
    const prev_site_name = sessionStorage.getItem("prev_site_name");
    const prev_site_tz = sessionStorage.getItem("prev_site_tz");
    // LogUserAction(
    //   "User site select site_id:" + prev_site_id + "site_name" + prev_site_name
    // );

    // console.log('SiteSelect prev_site_id', prev_site_id)

    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            <div>Site ID: {prev_site_id}</div>
            <div>Site Time Zone: {prev_site_tz}</div>
          </Tooltip>
        }
        color="black"
      >
        <div>
          <Select
            options={this.site_options}
            className="mt-2 mb-2"
            //Use the change script from the caller page
            onChange={this.props.handleSiteChange}
            defaultValue={{ label: prev_site_name, value: prev_site_id }} //Set the value/text
            placeholder="Select a Site"
            isDisabled={this.props.isDisabled || this.site_options.length <= 1}
          />
        </div>
      </OverlayTrigger>
    );
  }

  getSiteData() {
    // console.log("siteSelect2 getSiteData render");
    Fetch("functions_api.php", {
      action: "pop_selectSite",
      obj_id: "selectSite",
    }).then((data) => {
      // console.log("get_all_sites fetch date", data.data);
      if (data.status === "ok") {
        //Build the selection options

        //declare data leength here use bool send in props
        if (data.data.length === 1) {
          sessionStorage.setItem("prev_site_id", data.data[0].site_id);
          sessionStorage.setItem("prev_site_tz", data.data[0].site_tz);
          sessionStorage.setItem("o_value", data.data[0].o_value);
          sessionStorage.setItem("prev_site_name", data.data[0].o_text);
        }
        this.site_options = data.data.map((val) => ({
          label: val.o_text,
          value: val.site_id,
          site_tz: val.site_tz,
          o_value: val.o_value,
        }));

        this.setState({});
      } else {
        console.log("Fetch ERROR", data);
      }
    });
  }
}

export default SiteSelect;
