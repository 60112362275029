import React, {Component} from "react";
import Navigation from "../Navbar";
import SiteSelect from "../Global/Site_select2";
import {Fetch} from "../../helpers/Fetch";
import Select from "react-select";
import ExtraLineTable from "./ExtraLineTable";
import Test from "./test"

import "./Extra_Line_Items.css";

export default class Extra_Line_Items extends Component {
    constructor(props) {
        super(props);
        this.state = {
            period_select_data: [],
            period_disabled: true,
            site_id: sessionStorage.getItem("prev_site_id"),
            date_effective: "",
            date_end: "",
            rate_id: sessionStorage.getItem("o_value"),
        };
    }

    componentDidMount() {
        const {rate_id} = this.state
        if (rate_id) {
            this.handle_site_change()
        }
    }

    handle_site_change = (e) => {
        const {rate_id} = this.state;
        Fetch("extra_line_items_api.php", {
            action: "pop_selectPeriod",
            obj_id: "selectPeriod",
            rate_id: e !== undefined ? e.o_value : rate_id,
        }).then((data) => {
            if (data.status === "ok") {
                if (e !== undefined) {
                    console.log('**** setState')
                    this.setState({
                        period_label: "",
                        period_select_data: data.data,
                        period_disabled: false,
                        // reporting_period_selected: false,
                        site_id: e.value,
                        site_tz: e.site_tz,
                        rate_id: e.o_value,
                    });
                } else {
                    console.log('**** setState populate period select')
                    this.setState({
                        period_label: "",
                        period_select_data: data.data,
                        period_disabled: false,
                        // reporting_period_selected: false,
                    });
                }
            } else {
                console.log('**** setState')
                this.setState({
                    show_error_message: true,
                });
            }
        });
        if (e !== undefined) {
            sessionStorage.setItem("prev_site_id", e.value);
            sessionStorage.setItem("prev_site_tz", e.site_tz);
            sessionStorage.setItem("prev_site_name", e.label);
            sessionStorage.setItem("o_value", e.o_value);
        }
    };
    handlePeriodChange = (e) => {

        console.log('e.value', e.value)
        //Update state with selection
        // console.log('**** setState')
        // this.setState({rate_effective_id: e.value})
        const {site_id, rate_effective_id} = this.state;

        console.log('handlePeriodChange', e.value, site_id, rate_effective_id)
        console.log('handlePeriodChange state', this.state)

        console.log('**** setState handlePeriodChange')
        this.setState({
            period_label: e && e.label,
            // reporting_period_selected: true,
            // extras_data: data.data,
            rate_effective_id: e && e.value ? e.value : rate_effective_id,
            // reporting_period_status_keep: data.status_keep === "true",
        });

        // Fetch("extra_line_items_api.php", {
        //     action: "fill_extras_table",
        //     site_id: site_id,
        //     rate_effective_id: e !== undefined ? e.value : rate_effective_id,
        // }).then((data) => {
        //     if (data.status === "ok") {
        //         this.setState({
        //             period_label: e && e.label,
        //             reporting_period_selected: true,
        //             extras_data: data.data,
        //             rate_effective_id: e && e.value ? e.value : rate_effective_id,
        //             reporting_period_status_keep: data.status_keep === "true",
        //         });
        //     }
        // });
    };

    render() {
        const {
            period_select_data,
            period_label,
            rate_effective_id,
            site_id,
            period_disabled,
            // extras_data,
        } = this.state;

        const period_options = period_select_data.map((val) => ({
            label: val.o_text,
            value: val.o_value,
            date_end: val.date_end,
            date_effective: val.date_effective,
        }));

        const defaultSortBy = [{id: "Apartment", desc: false}]; //Default sort - use column names
        const hiddenColumns = ["report_bill_id"]; //No columns hidden in display

        return (
            <div>
                <Navigation/>
                <div className="row">
                    <div className="col-3 offset-2">
                        <div className="input-group">
                            <label htmlFor="select_site" className="mt-3 me-2">
                                Select Site
                            </label>
                            <div className="col-9">
                                <SiteSelect handleSiteChange={this.handle_site_change}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-7">
                        <div className="input-group col-12">
                            <label className="mt-3 me-2">Period</label>
                            <Select
                                className="col-4 mt-2"
                                options={period_options}
                                placeholder="Select a Period"
                                value={{label: period_label, value: rate_effective_id}}
                                isDisabled={period_disabled}
                                onChange={this.handlePeriodChange}
                            />
                        </div>
                    </div>
                </div>
                {rate_effective_id && site_id && (
                    <Test
                        rate_effective_id={rate_effective_id}
                        site_id={site_id}
                    />
                )}

                {/*<ExtraLineTable*/}
                {/*    // data={extras_data}*/}
                {/*    rate_effective_id={rate_effective_id}*/}
                {/*    site_id={site_id}*/}
                {/*    // defaultSortBy={defaultSortBy}*/}
                {/*    // hiddenColumns={hiddenColumns}*/}
                {/*/>*/}

                    </div>
                    );
                }
}
