/*
- Document each subcomponent
AlarmRow.js - This code creates the alarm rows and is responsible for its colouring
AlarmTable.js - This code creates the alarm table
Alarm.css - This code styles the alarm page ie. the alarm rows
 */

import React, { Component } from "react";
import Navigation from "../Navbar";
import AppFooter from "../AppFooter";
import { svr_name } from "../../helpers/AppConstants";
import AlarmTable from "./AlarmTable";
import { Fetch } from "../../helpers/Fetch";
import { Alert } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { LogUserAction } from "../../helpers/LogUserAction";

export default class Alarm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unacked_active_alarm: "",
      unacked_alarm: "",
      alarm_data: [], //All alarm rows
      option: "",
      sites: [],
      alarm_ids: [],
      site_id: 0,
      active_class: "active", //The active css class selected on toggle
      not_active_class: "notActive", // The inactive css class on toggle
      alarm_row: "",
      snooze_minutes: 60,
      checked_alarms: false,
    };
    this.site_id = 0;
  }

  componentDidMount() {
    this.checkAlarmStatus();
    this.initializeAlarmStatus();
    this.initializeAlarmSites();
  }

  componentWillUnmount() {
    clearInterval(this.alarmInterval);
  }

  initializeAlarmSites = () => {
    Fetch("alarm_api.php", {
      action: "pop_selectSite",
      obj_id: "selectSite",
    }).then((data) => {
      if (data.status === "ok") {
        //console.log(data.data);
        this.setState({
          sites: data.data,
        });
      } else {
        console.log("Fetch ERROR", data);
      }
    });
  };

  initializeAlarmStatus = () => {
    console.log("initializeAlarmStatus");
    Fetch("alarm_api.php", {
      action: "get_all_alarms",
      option: "clean_start",
      alarm_tz: "",
      site_id: 0,
    }).then((data) => {
      //console.log(data);
      if (data.status === "ok") {
        this.setState({
          unacked_active_alarm: data.unacked_active_alarm,
          unacked_alarm: data.unacked_alarm,
          alarm_data: data.data,
        });
      } else {
        console.log("Fetch ERROR", data);
      }
    });
  };

  checkAlarmStatus = () => {
    this.alarmInterval = setInterval(() => {
      //console.log('** checkAlarmStatus **');
      Fetch("alarm_api.php", {
        action: "get_all_alarms",
        option: "refresh_check",
        alarm_tz: "",
        site_id: this.site_id,
      }).then((data) => {
        //console.log(data);
        if (data.status === "ok" || data.status === "same") {
          if (data.status === "ok") {
            //New data - table refresh required
            this.setState({
              unacked_active_alarm: data.unacked_active_alarm,
              unacked_alarm: data.unacked_alarm,
              alarm_data: data.data,
            });
          }
        } else {
          console.log("Fetch ERROR", data);
        }
      });
    }, 5000);
  };

  handleSiteChange = (e) => {
    this.site_id = e.target.value;
    let options =
      parseInt(e.target.value) === 0 ? "clean_start" : "refresh_check";

    Fetch("alarm_api.php", {
      action: "get_all_alarms",
      option: options,
      alarm_tz: "",
      site_id: e.target.value,
    }).then((data) => {
      //console.log(data);
      if (data.status === "ok") {
        this.setState({
          alarm_data: data.data,
          site_id: e.target.value,
        });
      } else {
        console.log("Fetch ERROR", data);
      }
    });
  };

  /**
   * Ack selected alarms
   */
  handleAlarmAck = () => {
    const { alarm_ids } = this.state;

    console.log("alarm_ids", alarm_ids);
    // let data = {
    //     action: "ack_alarm_multi",
    //     alarm_ids: alarm_ids  //Alarm IDs of selected rows
    // }

    //Clear all checkboxes
    let checkboxes = document.getElementsByName("alarm-row-checkbox");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = false;
    }

    Fetch("alarm_api.php", {
      action: "ack_alarm_multi",
      alarm_ids: alarm_ids, //Alarm IDs of selected rows
    }).then((data) => {
      //console.log(data);
      this.setState({
        // reset_alarm_ids
        alarm_ids: [],
      });
    });
  };

  /**
   * Ack all Unacked alarms
   */
  handleAckMulti = () => {
    console.log("handleAckMulti");

    const { alarm_data } = this.state;

    let alarm_ids = [];
    alarm_data.forEach((a) => {
      //console.log(a);  //a = alarm row
      if (a.ackd === "None")
        //If not already Ack'd
        alarm_ids.push(a.alarm_id);
    });

    console.log("alarm_ids", alarm_ids);

    //Clear all checkboxes if any
    let checkboxes = document.getElementsByName("alarm-row-checkbox");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = false;
    }

    LogUserAction("Alarm: alarm ack all");

    Fetch("alarm_api.php", {
      action: "ack_alarm_multi",
      alarm_ids: alarm_ids, //Alarm IDs of rows to be acked
    }).then((data) => {
      //console.log(data);
    });
  };

  handleCheckBoxChange = (alarm_row) => {
    this.setState({
      alarm_row: alarm_row,
      alarm_ids: [
        ...this.state.alarm_ids, //all the previous alarm_ids
        alarm_row.alarm_id,
      ],
    });
  };

  handleSnoozeSelected = () => {
    const { snooze_minutes, alarm_ids } = this.state;
    let checkboxes = document.getElementsByName("alarm-row-checkbox");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = false;
    }

    fetch(svr_name + "alarm_api.php", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "snooze_selected",
        // alarm_config_id:alarm_row.alarm_config_id,
        alarm_config_id: alarm_ids,
        snooze_minutes: snooze_minutes,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({
          alarm_ids: [],
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleMinutesChange = (e) => {
    this.setState({
      snooze_minutes: e.target.value,
    });
  };

  render() {
    const {
      alarm_data,
      unacked_alarm,
      unacked_active_alarm,
      sites,
      checked_alarms,
    } = this.state;
    return (
      <>
        <Navigation />
        <div className="container-fluid main-container-div">
          <div className="offset-1" align="center">
            {isMobile && (
              <Alert variant="primary">
                Switch to desktop/larger screen to view hidden columns
              </Alert>
            )}

            <div className="btn-group mt-2 me-2">
              <p style={{ fontSize: 14 }}>Alarm count: {alarm_data.length}</p>
            </div>
            {/*<SiteSelect  site_data={sites} handleSiteChange={this.handleSiteChange} />*/}
            <select
              className="mt-2 mb-2 form-control form-select me-2"
              style={{
                width: "50%",
                backgroundColor: "#007bff",
                color: "white",
                display: "inline",
              }}
              defaultValue="Select a site"
              onChange={(e) => this.handleSiteChange(e)}
            >
              {sites.map((val, index) => (
                <option key={index} value={val.o_value}>
                  {val.o_text}
                </option>
              ))}
            </select>
            <button
              className="btn btn-primary me-2 mb-2 "
              onClick={this.handleAckMulti}
            >
              ACK All
            </button>
            <button
              className="btn btn-primary mb-2 d-none d-sm-none d-md-none d-lg-none d-xl-inline"
              onClick={this.handleAlarmAck}
            >
              ACK Selected
            </button>
          </div>
          <AlarmTable
            alarm_data={alarm_data}
            unacked_active_alarm={unacked_active_alarm}
            unacked_alarm={unacked_alarm}
            handleCheckBoxChange={this.handleCheckBoxChange}
            checked_alarms={checked_alarms}
          />
          <AppFooter />
        </div>
      </>
    );
  }
}
