import React, {useMemo, useState} from "react";
import {useTable, useSortBy, useFilters, usePagination} from "react-table";
import {Button, Form, Modal, Table} from "react-bootstrap"

export default function Tenant_Info_Table({data, defaultSortBy, hiddenColumns}) {
    const columns_new = [
        {
            Header: () => <div className="me-5">Report Group Name</div>, //Text of the header
            accessor: "report_group_name", //Property name in data
            key: "report_group_name"
        },
        {
            Header: () => <div className="me-5">Organisation Name</div>,
            accessor: "org_name",
            key: "org_name",
            class: "w-30",
        },
        {
            Header: () => <div className="me-5">First Name</div>,
            accessor: "fname",
            key: "fname",
            class: "w-50 d-none d-md-table-cell",
        },
        {
            Header: () => <div className="me-5">Last Name</div>,
            accessor: "lname",
            key: "lname",
            class: "w-50 d-none d-md-table-cell",
        },
        {
            Header: () => <div className="me-5">Action</div>,
            accessor: "action",
            key: "action",
            Cell: (props) => (
                <div className="text-center me-5 ">
                    {
                        <button
                            className="btn-primary btn-sm"
                            onClick={() => handleShowEditModal(props.row.original)}
                        >
                            Edit
                        </button>
                    }
                </div>
            ),
        }
    ];

    const columns = useMemo(
        () => [
            {
                Header: "Tenant Info",
                columns: columns_new,
            },
        ], []) // eslint-disable-line react-hooks/exhaustive-deps

    //Set up the filtering
    const [showFilters, setshowFilters] = React.useState(false)
    const [stateHiddenColumns] = React.useState(hiddenColumns)
    const filterTypes = {}
    const ColumnFilter = ({column: {filterValue, setFilter, filter}}) => {
        return (
            <Form.Group className="input-group input-group-sm">
                <Form.Control
                    type="search"  //adds the html5 x clear icon
                    placeholder={`Search ${filter ? filter : ""}...`}
                    // name={name}
                    value={filterValue || ""}
                    onChange={e => {
                        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                    }}
                />
            </Form.Group>
        );
    };

    const defaultColumn = {
        // Let's set up our default Filter UI
        Filter: ColumnFilter
    };

    // Use the useTable Hook to send the columns and data to build the table
    const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        page, // rows for the table based on the data passed
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
        setSortBy,
        setAllFilters,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
            columns,
            data,
            filterTypes,
            defaultColumn,
            initialState: {
                sortBy: defaultSortBy
            },
            setAllFilters: [],
        },
        useFilters,
        useSortBy,
        usePagination
    );

    const [showEditModal, setshowEditModal] = useState(false);
    const [editValues, seteditValues] = useState({});

    function handleCloseEditModal() {
        setshowEditModal(false);
    }

    function handleShowEditModal(value) {
        console.log(value);
        seteditValues(value)
        setshowEditModal(true);
    }

    function handleMultiSortBy(column, setSortBy, meinSortBy) {
        /*
        isSorted  isSortedDesc    setSortBy
        false       undefined       false  no sorting now - turn on asc
        false       true            Not possible
        true        false           true  sorting asc now - set desc
        true        true            set back to default
         */

        // console.log('defaultSortBy', defaultSortBy)
        let sortByArr;
        if (!column.isSorted)  //no sorting now - turn on asc
            sortByArr = [{id: column.id, desc: false}]
        else {
            if (!column.isSortedDesc) //sorting asc now - set desc
                sortByArr = [{id: column.id, desc: true}]
            else //Set back to default
                sortByArr = defaultSortBy //Set back to default

        }
        console.log('sortByArr', sortByArr)
        setSortBy(sortByArr);
    }

    function changeFilterShow(e) {
        // console.log(e.target.checked)
        setshowFilters(e.target.checked)
        setAllFilters([])
    }

    /*
      Render the UI for your table
      - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
    */
    return (
        <div className="table-responsive">
            <Table className="table table-bordered " {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr className="text-center" {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                            // console.log('column', column)
                            // Hide if column != hidden column or a special column
                            return (stateHiddenColumns.find((e) => column.id === e) || (column.id.substring(0, 2) === 'x_') ?
                                    null :
                                    <th {...column.getHeaderProps()}>
                                        <div className="d-print-none">
                                            {/* Main Table Header */}
                                            {column.depth === 0 && (
                                                <div className="col-12">
                                                    <div className="form-check mt-1 float-start">
                                                        <input type="checkbox" name="filterCheck"
                                                               onChange={changeFilterShow.bind(this)}/>
                                                    </div>
                                                    <div
                                                        className="col-6 d-flex align-items-center justify-content-center">
                                                        {column.render("Header")}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {/* Individual Column Headers*/}
                                        {(column.depth > 0) && (
                                            <div className="col text-center"
                                                 onClick={() => handleMultiSortBy(column, setSortBy, defaultSortBy)}>
                                                <>
                                                    {(column.isSorted)
                                                        ? (column.isSortedDesc)
                                                            ? <i className="fas fa-arrow-down me-2"></i>
                                                            : <i className="fas fa-arrow-up me-2"></i>
                                                        : <></>}

                                                    {column.render("Header")}
                                                </>
                                            </div>
                                        )}
                                        {showFilters && (
                                            <div className="d-print-none" id={column.id}>
                                                {column.canFilter ? column.render("Filter") : null}
                                            </div>
                                        )}
                                    </th>
                            )
                        })}

                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row);
                    // console.log("row", row.original["x_row_color"])
                    return (
                        <tr {...row.getRowProps()} style={{backgroundColor: row.original["x_row_color"]}}>
                            {row.cells.map(cell => {
                                // console.log('cell', cell.column.id.substring(0,2))
                                //Do not render if a hidden column or a special column
                                return (stateHiddenColumns.find((e) => cell.column.id === e) || (cell.column.id.substring(0, 2) === 'x_') ?
                                        null :
                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                )
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </Table>
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {"<<"}
                </button>{" "}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {"<"}
                </button>{" "}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {">"}
                </button>{" "}
                <button
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                >
                    {">>"}
                </button>{" "}
                <span>
              Page{" "}
                    <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
                <span>
              | Go to page:{" "}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                        style={{ width: "100px" }}
                    />
            </span>{" "}
                <select
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
            <Modal show={showEditModal} onHide={handleCloseEditModal} size="lg">
                <Modal.Header closeButton className="text-center">
                    <Modal.Title>Edit User Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Report Group Name
                            </label>
                            <input type="text" value={editValues.report_group_name} disabled className="form-control" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Organisation Name
                            </label>
                            <input type="text" value={editValues.org_name} disabled className="form-control" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                First Name
                            </label>
                            <input type="text" value={editValues.fname} disabled className="form-control" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Last Name
                            </label>
                            <input type="text" value={editValues.lname} disabled className="form-control" />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleCloseEditModal}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}