import React from "react";
import {history} from "../helpers/history";
import {Button, NavDropdown, Nav, Navbar} from "react-bootstrap";
import "../styles/Navbar.css";
import {svr_name} from "../helpers/AppConstants";
import {installedComponents} from "../App";
import {Fetch} from "../helpers/Fetch";
import {LogUserAction} from "../helpers/LogUserAction";
import {isMobile} from "react-device-detect";

export default class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: "",
            unacked_active_alarm: "",
            unacked_alarm: "",
            set_user_menu: "",
            sel_user_val: null,
            selected_tab: ""
        };
        let retrieved_data = localStorage.getItem("login_data");
        this.parsed_data = retrieved_data
            ? JSON.parse(retrieved_data)
            : this.handleInValidLogin();
        this.user_menu = JSON.parse(localStorage.getItem("user_menu"))
            ? JSON.parse(localStorage.getItem("user_menu"))
            : [];
        this.dropItem =
            this.parsed_data &&
            this.parsed_data.user_menu.filter((item) => item.parent_tab_id !== null);
    }

    componentDidMount() {
        this.checkLoginStatus(); //todo: This needs to be done sooner
        this.checkAlarmStatus();
        this.recurrentAlarmStatus();
    }

    componentWillUnmount() {
        clearInterval(this.alarmInterval);
        // clearInterval(this.interval)
    }

    capture_selected_tab = (menu_item) => {
        LogUserAction(
            "Navigate: tab_text: " +
            menu_item.tab_text +
            " - tab_id: " +
            menu_item.tab_id +
            " - code_file_prefix: " +
            menu_item.code_file_prefix
        )
        console.log(menu_item.tab_text)
        sessionStorage.setItem("selected_menu_item.tab_id", menu_item.tab_id);
        console.log('******** setState - selected_tab')
        this.setState({
            selected_tab: menu_item.tab_text
        })
    }

    recurrentAlarmStatus = () => {
        this.alarmInterval = setInterval(() => {
            if (history.location.pathname !== "/alarms") {
                Fetch("alarm_api.php", {
                    action: "get_all_alarms",
                    option: "refresh_check",
                    alarm_tz: "",
                    site_id: 0,
                })
                    .then((data) => {
                        if (data.status === "ok") {
                            console.log('******** setState - alarms')
                            this.setState({
                                unacked_active_alarm: data.unacked_active_alarm,
                                unacked_alarm: data.unacked_alarm,
                            });
                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            }
        }, 5000);
    };

    checkAlarmStatus = () => {
        Fetch("alarm_api.php", {
            action: "get_all_alarms",
            option: "clean_start",
            alarm_tz: "",
            site_id: 0,
        })
            .then((data) => {
                // console.log(data);
                if (data.status === "ok") {
                    console.log('******** setState - alarm status')
                    this.setState({
                        unacked_active_alarm: data.unacked_active_alarm,
                        unacked_alarm: data.unacked_alarm,
                    });
                }
            })
            .catch((error) => {
                console.log("Error:", error);
            });
    };

    checkLoginStatus = () => {
        // console.log("window.location", window.location);

        this.interval = setInterval(() => {
            if (
                history.location.pathname !== "/login" &&
                history.location.pathname !== "/register"
            ) {
                fetch(svr_name + "login.php", {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({}),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.loginStatus !== "same") {
                            //
                            //If the status is not the same decide if good or bad. Else do nothing
                            if (data.loginStatus === "good") {
                                localStorage.setItem(
                                    "user_menu",
                                    JSON.stringify(data.user_menu)
                                );
                                console.log('******** setState - set_user_menu')
                                this.setState({
                                    set_user_menu: data.user_menu,
                                });
                            } else {
                                //console.log('login data', data);
                                clearInterval(this.interval); //stop the timer and no longer check login status
                                localStorage.clear();
                                sessionStorage.clear();
                                history.push("/login");
                                LogUserAction("Navigate: user logout timeout");
                            }
                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            }
        }, 10000);
    };

    navigate_to_device_admin = () => {
        history.push("/device_admin");
    };

    doUserLogout = () => {
        history.push("/login");
        sessionStorage.clear();
        localStorage.clear();

        LogUserAction("Navigate: user logout");
        const data = {
            logout: true,
        };
        fetch(svr_name + "login.php", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
    };

    createMenuItems = () => {
        const {unacked_active_alarm, unacked_alarm, set_user_menu} = this.state;
        let user_menu = set_user_menu.length > 0 ? set_user_menu : this.user_menu;
        let installed_apps = Object.keys(installedComponents);
        let items = [];
        //console.log(user_menu);
        for (let j = 0; j <= user_menu.length - 1; j++) {
            let menu_item = user_menu[j];

            //Top level menu tabs
            if (installed_apps.includes(menu_item && menu_item.code_file_prefix)) {
                //If page is installed
                // console.log("menu_item", menu_item)

                if (!menu_item.parent_tab_id && menu_item.code_file_prefix) {
                    //If parent_tab_id null and code_file_prefix not null THEN basic top level menu tab
                    // console.log("Top level menu_item", menu_item)

                    items.push(
                        <Nav.Item key={j}>
                            <Nav.Link
                                href={"#/" + menu_item.tab_id}
                                className={
                                    menu_item.tab_id === "alarms"
                                        ? unacked_active_alarm === "yes"
                                            ? "alarm-unack"
                                            : unacked_alarm === "yes"
                                                ? "alarm-unack"
                                                : unacked_alarm === "no"
                                                    ? "alarm-ack"
                                                    : "alarm-clear"
                                        : "nav-link"
                                }
                                onClick={() =>
                                    this.capture_selected_tab(menu_item)}
                            >
                                {menu_item.tab_text}
                            </Nav.Link>
                        </Nav.Item>
                    );
                }
            }

            //Drop down menu tabs
            if (!menu_item.code_file_prefix || menu_item.parent_tab_id) {
                //If code_file_prefix is null, top level dropdown tab
                //console.log(this.dropItem);
                for (var i = 0; i < this.dropItem.length; i++) {
                    //console.log('i=' + i);
                    let item = this.dropItem[i];
                    if (
                        item.parent_tab_id === menu_item.tab_id &&
                        installed_apps.includes(item.code_file_prefix)
                    ) {
                        //At least one installed item on drop down menu so create entire dropdown
                        items.push(
                            !menu_item.parent_tab_id ? (
                                <NavDropdown
                                    key={j}
                                    title={menu_item.tab_text}
                                    className="nav-custom"
                                >
                                    {
                                        //Push all dropdown items for this dropdown
                                        this.dropItem.map((val, index) =>
                                            val.parent_tab_id === menu_item.tab_id &&
                                            installed_apps.includes(val.code_file_prefix) ? (
                                                <NavDropdown.Item
                                                    key={"DD" + j}
                                                    onClick={() =>
                                                        LogUserAction(
                                                            "Navigate: menu tab: " + JSON.stringify(val) //.tab_id
                                                        )
                                                    }
                                                    href={"#/" + val.tab_id}
                                                >
                                                    {val.tab_text}
                                                </NavDropdown.Item>
                                            ) : (
                                                ""
                                            )
                                        )
                                    }
                                </NavDropdown>
                            ) : (
                                ""
                            )
                        );
                        break; //break inside for loop
                    }
                }
            }
        }
        items.push(
            <Button
                key="btn_logout"
                variant="secondary"
                onClick={this.doUserLogout}
                className="logout-button"
            >
                Logout
            </Button>
        );
        return items;
    };

    handleUserChange = (e) => {
        console.log('******** setState - handleUserChange')
        this.setState({
            sel_user_val: e.target.value,
        });
        sessionStorage.setItem("selected_user_id", e.target.value);
        fetch(svr_name + `login.php`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                // "Accept-Encoding": "gzip"
            },
            body: JSON.stringify({
                user_id: e.target.value,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.user_menu && data.user_menu.length > 0) {
                    localStorage.setItem("user_menu", JSON.stringify(data.user_menu));
                    console.log('******** setState - set_user_menu')
                    this.setState({
                        set_user_menu: data.user_menu,
                    });
                    history.push("/");
                }
            });
    };

    handleInValidLogin = () => {
        history.push("/login");
    };

    render() {
        let current_path = history.location.pathname;
        let current_tab = this.state.selected_tab
            ? current_path.substring(1, current_path.length)
            : "";

        let login_data = JSON.parse(localStorage.getItem("login_data"));
        let selected_user = sessionStorage.getItem("selected_user_id");
        const {image} = this.state;
        const default_user = selected_user
            ? selected_user
            : login_data
                ? login_data.master_user_id
                : this.handleInValidLogin();

        return (
            <div className="d-print-none">
                {/* The Login data.user_select is populated in api/login.php */}
                {login_data && login_data.user_select && (
                    <select
                        className="col-md-4 col-sm-12"
                        defaultValue={default_user}
                        onChange={this.handleUserChange}
                    >
                        <optgroup
                            className="text-center"
                            label="** Recent logins(60 days) **"
                        >
                            {login_data &&
                                login_data.user_select
                                    .sort((a, b) => a.days_last_login - b.days_last_login)
                                    .filter(
                                        (val) => val.days_last_login < 60 && val.days_last_login
                                    )
                                    .map((val, index) => (
                                        <option value={val.user_id} key={index}>
                                            {val.sel_text} - {val.days_last_login} days ago{" "}
                                        </option>
                                    ))}
                        </optgroup>
                        <optgroup
                            className="text-center"
                            label="** Old logins(>=60 days) **"
                        >
                            {login_data &&
                                login_data.user_select
                                    .sort((a, b) => a.days_last_login - b.days_last_login)
                                    .filter((val) => val.days_last_login >= 60)
                                    .map((val, index) => (
                                        <option value={val.user_id} key={index}>
                                            {val.sel_text} - {val.days_last_login} days ago{" "}
                                        </option>
                                    ))}
                        </optgroup>
                        <optgroup className="text-center" label="** Never Logged In **">
                            {login_data &&
                                login_data.user_select
                                    .filter((val) => !val.days_last_login)
                                    .map((val, index) => (
                                        <option value={val.user_id} key={index}>
                                            {val.sel_text}
                                        </option>
                                    ))}
                        </optgroup>
                    </select>
                )}
                <div className="col-12 nav-images">
                    {/*Echaser Logo*/}
                    <img
                        src="/img/ec_logo.gif"
                        className="ECLogo col-2"
                        href="/#"
                        alt="EC Logo"
                    />
                    {/*client Logo*/}
                    {this.parsed_data && this.parsed_data.site_logo && (
                        <img
                            src={`./logos/${this.parsed_data && this.parsed_data.site_logo}`}
                            className="CustLogo col-2"
                            height="100"
                            href="/#"
                            alt={"Customer Logo:" + image.default}
                        />
                    )}
                </div>

                <Navbar
                    className="col-12"
                    collapseOnSelect
                    expand="lg"
                    style={{zIndex: 750}}
                >
                    {isMobile && <Navbar.Brand>{current_tab}</Navbar.Brand>}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav variant="tabs" activeKey={window.location.hash} id="navbarNav">
                            {this.createMenuItems()}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}
