import React, { Component } from "react";

import { Fetch } from "../../helpers/Fetch";
import { CurrencyFormat } from "../../helpers/CurrencyFormat";

/*
Day JS Imports
 */

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

class LatestInvoiceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latest_invoice_data: [],
    };
  }
  componentDidMount() {
    const { site_id } = this.props;
    Fetch("utilityInvoices_api.php", {
      action: "fill_l_invoices_table",
      site_id: site_id,
    }).then((data) => {
      console.log("table data", data);
      this.setState({
        latest_invoice_data: data.data,
      });
    });
  }

  render() {
    const { latest_invoice_data } = this.state;
    return (
      <div>
        <table className="table table-striped table-bordered table-sm">
          <thead>
            <tr className="text-center">
              <th>Group Name</th>
              <th>Utility Name</th>
              <th>Account</th>
              <th>Service From</th>
              <th>Service To</th>
              <th>Invoice Date </th>
              <th>Days</th>
              <th>Charges</th>
              <th>Consumption</th>
              <th>Unit Cost</th>
            </tr>
          </thead>
          <tbody>
            {latest_invoice_data &&
              latest_invoice_data
                .sort((a, b) => b.service_from.localeCompare(a.service_from))
                .map((val, index) => (
                  <tr key={index} className="text-center">
                    <td>{val.report_group_name}</td>
                    <td>{val.utility_name}</td>
                    <td>{val.utility_account}</td>
                    <td>{dayjs(val.service_from).format("YYYY-MM-DD")}</td>
                    <td>{dayjs(val.service_to).format("YYYY-MM-DD")}</td>
                    <td>{dayjs(val.bill_date).format("YYYY-MM-DD")}</td>
                    <td>
                      {dayjs(val.service_to).diff(
                        dayjs(val.service_from),
                        "days"
                      )}
                    </td>
                    <td>
                      {"$" + CurrencyFormat().format(val.current_charges)}
                    </td>
                    <td>{CurrencyFormat(0).format(val.consumption)}</td>
                    <td>
                      {"$" +
                        CurrencyFormat(5).format(
                          val.current_charges / val.consumption
                        )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default LatestInvoiceTable;
