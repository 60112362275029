import React, { Component } from "react";
import Navigation from "../Navbar";
import BadDataTable from "./BadDataTable";
import { Fetch } from "../../helpers/Fetch";


class Bad_Data extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report_bill_id: "",
      profile_table_data: [],
      report_time_zone: "",
      report_name: "",
      report_group_name: "",
      trend_data: [],
      raw_data: [],
      submit_rbid: false,
      report_bill_id_placeholder:""
    };
  }
  handle_rbid_change = (e) => {
    this.setState({
      report_bill_id: e.target.value,
    });
  };

  get_profile_table_data = (e) => {
    e.preventDefault();
    const { report_bill_id } = this.state;
    Fetch("bad_data.php", {
      action: "get_report_info",
      report_bill_id: report_bill_id,
    }).then((data) => {
      if (data.status === "ok" && data.data.length > 0) {
        this.setState(
          {
            profile_table_data: data.data,
            report_time_zone: data.data[0].report_time_zone,
            report_name: data.data[0].report_name,
            report_group_name: data.data[0].report_group_name,
            report_bill_id_placeholder:report_bill_id
          }
        );
      } else {
        this.setState({
          profile_table_data: data.data,
          report_time_zone: "",
          report_name: "",
          report_group_name: "",
          submit_rbid: true,
        });
      }
    });
  };

  render() {
    const {
      report_bill_id,
      profile_table_data,
      report_group_name,
      report_name,
      report_time_zone,
      trend_data,
      submit_rbid,
      graph_difference,
      report_bill_id_placeholder
    } = this.state;
    return (
      <div>
        <Navigation />
        <h2 className="text-center">Bad Data Repair</h2>
        <div className="row">
          <div className="offset-4 col-2">
            <label>Enter Report Bill ID:</label>
          </div>

          <div className="col-6">
            <form onSubmit={this.get_profile_table_data}>
              <input
                className="form-control-sm"
                value={report_bill_id}
                onChange={(e) => this.handle_rbid_change(e)}
                type="number"
              />
            </form>
          </div>
        </div>

        <div>
          <div className="container">
            <hr className="mt-3" />
            <div className="row">
              <div className="col-2">
                <label>Group Name:</label>
              </div>
              <div className="col-3">
                <input
                  className="form-control"
                  value={report_group_name}
                  type="text"
                  readOnly
                />
              </div>
              <div className="offset-2 col-2">
                <label>Report Name:</label>
              </div>
              <div className="col-3">
                <input
                  className="form-control"
                  value={report_name}
                  type="text"
                  readOnly
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-2">
                <label>Site Time Zone:</label>
              </div>
              <div className="col-3">
                <input
                  className="form-control"
                  value={report_time_zone}
                  type="text"
                  readOnly
                />
              </div>
            </div>
            <BadDataTable
              trend_data={trend_data}
              profile_table_data={profile_table_data}
              site_tz={report_time_zone}
              report_bill_id={report_bill_id_placeholder}
              submit_rbid={submit_rbid}
              graph_difference={graph_difference}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Bad_Data;
