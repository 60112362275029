import * as React from 'react'
import {useEffect, useMemo, useRef} from "react";
import Table from 'react-bootstrap/Table';

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
} from '@tanstack/react-table'
import {CurrencyFormat} from "../../helpers/CurrencyFormat";
import {Fetch} from "../../helpers/Fetch";

export default function Test(params) {


    const update = useMemo(
        () => ({count: 0}),
        [] //no dependencies so the value doesn't change
    );

    const [updatex, setUpdate] = React.useState(update);


    // const [data, setData] = React.useState([]);
    // const dataMemo = React.useMemo(() => {
    //     return data
    // }, [update])

    let data = useRef([])
    let rate_effective_id = useRef(0)
    let site_id = useRef(0)
    let nextFocus = useRef(null)

    // rate_effective_id.current = params.rate_effective_id
    // let dataUpdate = useMemo(() => {
    //     return update
    // })

    console.log('**************** render ExtraLineTable ******************', update, site_id.current, rate_effective_id.current, params, data)

    /**
     * Get data from DB
     */
    useEffect(() => {

        if ((rate_effective_id.current !== params.rate_effective_id) || (site_id.current !== params.site_id)) {

            site_id.current = params.site_id
            rate_effective_id.current = params.rate_effective_id


            console.log('FETCH new data rate_effective_id', params.rate_effective_id, params.site_id)
            // if (rate_effective_id.current !== undefined) {
            Fetch("extra_line_items_api.php", {
                action: "fill_extras_table",
                site_id: params.site_id,
                rate_effective_id: params.rate_effective_id,
            }).then((fdata) => {
                // console.log('fetch data', fdata)
                if (fdata.status === "ok") {

                    //data.current = fdata.data
                    console.log('***setData', update.count, fdata.data)

                    data.current = fdata.data

                    update.count = update.count + 1
                    setUpdate(update.count + 1)
                    console.log('****** fetch new data done', update.count, data.current)
                }
            });
        }
    }, [update.count])

    /**
     * Does simple min/max validation of numbers
     * error defaults to an empty string or is-invalid if failed
     * errorText defaults to an empty string or a message if failed
     * @param {real} min
     * @param {real} max
     * @param {real} value
     * @returns {{errorText: string, error: string}}
     */
    function doSimpleValidation(min, max, value) {
        let errorObj = {error: '', errorText: ''}
        if (value < min)  //value less than min value
            errorObj = {
                error: ' is-invalid',
                errorText: 'Value must be greater than ' + min
            }

        if (value > max) //value greater than max value
            errorObj = {
                error: ' is-invalid',
                errorText: 'Value must be less than ' + max
            }

        // console.log('doSimpleValidation new_val min max', value, min, max, errorObj)

        return errorObj
    }

    function handelKeypress(e) {

        console.log('**** keyPress', e)
        if ((e.key === 'Enter') || (e.key === 'Tab')) { //Enter or Tab key pressed
            e.preventDefault()

            //Get/set the next focus field
            var nextInput = document.querySelectorAll('[tabIndex="' + (e.target.tabIndex + 1) + '"]');
            if (nextInput.length === 0) {
                nextInput = document.querySelectorAll('[tabIndex="1"]');
            }
            nextInput[0].focus();  //triggers onBlur event for the input to write to DB
        }
    }

    function write_DB(e, accessor) {

        console.log('write_DB', e, accessor)

        // let new_val = e.target.value

        if (e.target.value !== e.target.defaultValue) {
            //If the value is not the same as the defaultValue/value from DB

            //Get the current/next focus field
            var nextInput = document.querySelectorAll('[tabIndex="' + (e.target.tabIndex + 1) + '"]');
            if (nextInput.length === 0) { //Default tabIndex="1"
                nextInput = document.querySelectorAll('[tabIndex="1"]');
            }

            let new_val = e.target.value
            let id = e.target.id
            // let accessor = updateInfo.accessor
            // let nextInput_id = nextFocus

            //Field to update
            //Example ID: accessor_2400_2014_0 where accessor is the DB name of the field
            //c.report_bill_id + "_" + c.energy_user_id + "_" + c.item_no
            let field = id.substring(0, accessor.length)

            //Get report_bill_id
            let x = id.indexOf('_', accessor.length) //First _ after accessor
            let y = id.indexOf('_', x + 1) //Second _
            let rb_id = id.substring(x + 1, y)

            //Get energy_user_id
            x = id.indexOf('_', y + 1) //Third _
            let eu_id = id.substring(y + 1, x)

            //Get item_no
            let item_no = id.substring(x + 1)
            console.log('rb_id, eu_id, item_no', rb_id, eu_id, item_no)
            console.log('Action: update to DB' + field, new_val)

            Fetch("extra_line_items_api.php", {
                action: "update" + field,
                rb_id: rb_id,
                eu_id: eu_id,
                item_no: item_no,
                new_val: new_val
            }).then((fdata) => {
                if (fdata.status === "ok") {
                    data.current = fdata.data
                    nextFocus.current = nextInput.id
                } else {
                    console.log('status not OK', fdata)
                }
            })
        }
    }


    function handelOnBlur(e, accessor) {
        e.preventDefault()
        console.log('handelOnBlur', e, accessor)

        write_DB(e, accessor)

    }

    function processCell(cell, accessor) {
        // console.log('chargeColumn', cell.getValue(), cell.row.original)

        //Generate input id with the parts required to update DB
        let c = cell.row.original
        let input_id = accessor + "_" + c.report_bill_id + "_" + c.energy_user_id + "_" + c.item_no
        //console.log('columnHelper focus', state.focus)

        //Get row number in table
        let r = table.getSortedRowModel().rows
        let row_num = r.findIndex((row) => {
            //console.log('inside', row.original, cell.row.original)
            return row.original === cell.row.original
        })
        //console.log('chargeColumn rows', row_num, r)

        return {'input_id': input_id, 'row_num': row_num}
    }

    const columnHelper = createColumnHelper()

    const columns = React.useMemo(() => {
        return [
            columnHelper.accessor('report_bill_id', {
                header: () => 'RBID',
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('energy_user_id', {
                header: () => 'EUID',
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('report_name', {
                header: () => 'Space',
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('org_name', {
                header: () => 'Tenant Name',
                cell: info => info.getValue(),
            }),
            // columnHelper.accessor('charge_amount', {
            //     header: () => 'Unit Charge',
            //     cell: info => info.getValue(),
            // }),
            columnHelper.accessor('quantity', {
                    header: () => 'Quantity',
                    cell: cell => {

                        let accessor = 'quantity'
                        let cellObj = processCell(cell, accessor)

                        const OnBlurEvent = (e) => {
                            handelOnBlur(e, accessor)
                        }

                        const KeyPressEvent = (e) => {
                            handelKeypress(e)
                        }

                        //Put the focus on the next field
                        let autoFocus = false
                        if (nextFocus.current === cellObj.input_id) {
                            autoFocus = true
                        }

                        let validate = doSimpleValidation(0, 100000, cell.getValue())

                        //quantity
                        return (
                            <div className="input-group-sm">
                                <input className={"form-control text-end" + validate.error}
                                       id={cellObj.input_id}
                                       defaultValue={cell.getValue()}
                                       type="number"
                                       tabIndex={cellObj.row_num}
                                       onKeyDown={(e) => KeyPressEvent(e)}
                                       onBlur={(e) => OnBlurEvent(e)}
                                       autoFocus={autoFocus}
                                />
                                <div className="invalid-feedback">
                                    {validate.errorText}
                                </div>
                            </div>
                        )
                    }
                }
            ),
            columnHelper.accessor('charge_amount', {
                    header: () => 'Unit Charge',
                    cell: cell => {

                        let accessor = 'charge_amount'
                        let cellObj = processCell(cell, accessor)

                        const OnBlurEvent = (e) => {
                            handelOnBlur(e, accessor)
                        }

                        const KeyPressEvent = (e) => {
                            handelKeypress(e)
                        }

                        //Put the focus on the next field
                        let autoFocus = false
                        if (nextFocus.current === cellObj.input_id) {
                            autoFocus = true
                        }

                        let validate = doSimpleValidation(0, 100000, cell.getValue())

                        //quantity
                        return (
                            <div className="input-group-sm">
                                <input className={"form-control text-end" + validate.error}
                                       id={cellObj.input_id}
                                       defaultValue={CurrencyFormat().format(cell.getValue())}
                                       type="number"
                                       tabIndex={cellObj.row_num + 1000}
                                       onKeyDown={(e) => KeyPressEvent(e)}
                                       onBlur={(e) => OnBlurEvent(e)}
                                       autoFocus={autoFocus}
                                />
                                <div className="invalid-feedback">
                                    {validate.errorText}
                                </div>
                            </div>
                        )
                    }
                }
            ),
            columnHelper.accessor('extended', {
                header: () => 'Extended',
                cell: cell => {
                    console.log(cell)
                    return (
                        <div className="input-group input-group-sm">
                            <span className="input-group-text" id="inputGroup-sizing-sm">$</span>
                            <input className="form-control text-end"
                                   defaultValue={CurrencyFormat().format(cell.getValue())}
                                   type="text"
                                   disabled
                            />
                        </div>
                    )
                }
            }),
            columnHelper.accessor('item_description', {
                    header: () => 'Description',
                    width: 700,
                    cell: cell => {
                        let accessor = 'item_description'
                        let cellObj = processCell(cell, accessor)

                        const OnBlurEvent = (e) => {
                            handelOnBlur(e, accessor)
                        }

                        const KeyPressEvent = (e) => {
                            handelKeypress(e)
                        }

                        //Put the focus on the next field
                        let autoFocus = false
                        if (nextFocus.current === cellObj.input_id) {
                            autoFocus = true
                        }

                        return (
                            <div className="input-group-sm">
                                <input className={"form-control"}
                                       id={cellObj.input_id}
                                       defaultValue={cell.getValue()}
                                       type="text"
                                       tabIndex={cellObj.row_num + 2000}
                                       onKeyDown={(e) => KeyPressEvent(e)}
                                       onBlur={(e) => OnBlurEvent(e)}
                                       autoFocus={autoFocus}
                                />
                            </div>
                        )
                    }
                }
            ),
            columnHelper.accessor('item_no', {
                header: () => 'Item',
                cell: info => info.getValue(),
            }),
        ]
    })


    let columnsFixed = React.useMemo(() => {
        return columns
    }, [])

    console.log('Table data', data.current)
    const table = useReactTable({
        data: data.current,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        state: {
            sorting: [
                {
                    id: "report_name",
                    desc: false
                },
                {
                    id: "item_no",
                    desc: false
                }],
            columnVisibility: {
                'item_no': false,
            },
        },
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    })

    return (
        <div className="table-responsive">
            {data.current.length > 0 && (
                <Table bordered size="sm">
                    {/*<table className="table table-sm">*/}
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getSortedRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}
        </div>
    )
}